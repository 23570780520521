import { Button, Tooltip } from "primereact";
import React from "react";

interface DataTableActionTemplateProps {
 openDeleteDialog?: () => void;
 openEditDialog?: () => void;
}

const DataTableActionTemplate: React.FC<DataTableActionTemplateProps> = ({
 openDeleteDialog,
 openEditDialog,
}) => {
 return (
  <>
   <Tooltip target="#p-edit-button" position="bottom" content="Edit" />
   <Tooltip target="#p-delete-button" position="bottom" content="Delete" />

   {openEditDialog !== undefined && (
    <>
     <Button
      icon="pi pi-pencil"
      id="p-edit-button"
      className="p-button-rounded p-button-success m-2"
      onClick={() => openEditDialog()}
     />
    </>
   )}
   {openDeleteDialog !== undefined && (
    <>
     <Button
      id="p-delete-button"
      icon="pi pi-trash"
      className="p-button-rounded p-button-warning m-2"
      onClick={() => openDeleteDialog()}
     />
    </>
   )}
  </>
 );
};

export default DataTableActionTemplate;
