import { ROLE } from "@/constants";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
 role: ROLE | undefined;
}

const DashboardHeader: React.FC<Props> = ({ role }) => {
 const { t } = useTranslation();
 return (
  <div className="card mb-0 w-full">
   <h1 className="text-2xl font-bold m-0">
    {`${role ? role.charAt(0) + role.slice(1).toLocaleLowerCase() : ""} ${t(
     "Dashboard",
    )}`}
   </h1>
  </div>
 );
};

export default DashboardHeader;
