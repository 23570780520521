import { getInActiveOrders } from "@/services";
import { useQuery } from "react-query";

export const useGetInActiveOrdersData = () => {
 const { data: orders, isLoading: getInActiveOrdersIsLoading } = useQuery(
  "inactiveorders",
  getInActiveOrders,
 );
 return { orders, getInActiveOrdersIsLoading };
};
