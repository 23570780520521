import { Col } from "@/layouts";
import { Skeleton } from "primereact";

interface DashboardCardProps {
 title: string;
 count: number | string;
 iconBgColor: string;
 iconTextColor: string;
 icon: string;
 footerTitle?: string | number;
 footerSubTitle?: string | number;
 isLoading?: boolean;
 className?: string;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
 title,
 count,
 icon,
 iconBgColor,
 iconTextColor,
 footerTitle,
 footerSubTitle,
 isLoading = false,
 className = "col-12 lg:col-6",
}) => {
 return (
  <Col className={className}>
   <div className="card mb-0">
    <div className="flex justify-content-between mb-3">
     <div>
      <span className="block text-500 font-medium mb-3">{title}</span>
      {!isLoading ? (
       <div className="text-green-500 font-medium text-xl">{count}</div>
      ) : (
       <Skeleton className="mb-2" width="5rem" />
      )}
     </div>
     <div
      className={`flex align-items-center justify-content-center border-round ${iconBgColor}`}
      style={{ width: "2.5rem", height: "2.5rem" }}
     >
      <i className={`pi text-xl ${icon} ${iconTextColor}`} />
     </div>
    </div>
    {!footerTitle ? null : !isLoading ? (
     <span className="text-900 font-medium">{footerTitle}</span>
    ) : (
     <Skeleton className="mb-2" width="5rem" />
    )}
    {!footerSubTitle ? null : !isLoading ? (
     <span className="text-900 font-medium">{footerSubTitle}</span>
    ) : (
     <Skeleton className="mb-2" width="5rem" />
    )}
   </div>
  </Col>
 );
};

export default DashboardCard;
