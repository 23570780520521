import { ROLE } from "@/constants";
import { createContext, Dispatch, SetStateAction } from "react";

const role: ROLE | undefined = ROLE.OPERATOR;
const setRole: Dispatch<SetStateAction<ROLE | undefined>> = () => {};

export default createContext({
 role: role as ROLE | undefined,
 setRole,
});
