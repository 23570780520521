import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import logoLight from "@assets/images/logo-white.svg";
import logoDark from "@assets/images/logo-dark.svg";
import { useMenuToggle, useMobileMenuToggle, useTheme } from "@/hooks";
import { appName, CONFIG_VARS, profileItems } from "@constants/index";
import { Menu } from "primereact";
import { TopbarMenuItems, TopbarMenu, LanguageDialog } from "@/components";
import { isDesktop } from "@/utils";

const AppTopbar = () => {
 const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
 const [openDialog, setOpenDialog] = useState(false);
 const profileMenuRef = useRef<Menu>(null);
 const { setMenuIsActive } = useMenuToggle();
 const { setMobileMenuIsActive } = useMobileMenuToggle();
 const { layoutColorMode, setLayoutColorMode } = useTheme();

 const onMobileTopbarMenuClick = (event: React.MouseEvent) => {
  event.preventDefault();
  setMobileTopbarMenuActive((prevState) => !prevState);
 };

 const onSideBarMenuClick = (event: React.MouseEvent) => {
  event.preventDefault();
  setMenuIsActive((prevState) => !prevState);
  !isDesktop() && setMobileMenuIsActive((prevState) => !prevState);
 };

 const closeDialog = () => {
  setOpenDialog(false);
 };

 const changeTheme = () => {
  setLayoutColorMode((prevState) =>
   prevState === CONFIG_VARS.LIGHT ? CONFIG_VARS.DARK : CONFIG_VARS.LIGHT,
  );
 };

 return (
  <div className="layout-topbar">
   <Link to="/" className="layout-topbar-logo">
    <img
     src={layoutColorMode === CONFIG_VARS.LIGHT ? logoDark : logoLight}
     alt="logo"
    />
    <span>{appName}</span>
   </Link>

   <button
    type="button"
    className="p-link  layout-menu-button layout-topbar-button"
    onClick={(e) => onSideBarMenuClick(e)}
   >
    <i className="pi pi-bars" />
   </button>

   <button
    type="button"
    className="p-link layout-topbar-menu-button layout-topbar-button"
    onClick={(e) => onMobileTopbarMenuClick(e)}
   >
    <i className="pi pi-ellipsis-v" />
   </button>

   <TopbarMenu active={mobileTopbarMenuActive}>
    <TopbarMenuItems
     title="Events"
     iconClassName="pi pi-sun"
     onClick={() => changeTheme()}
    />
    <TopbarMenuItems
     title="Language"
     iconClassName="pi pi-language"
     onClick={() => setOpenDialog(true)}
    />
    <TopbarMenuItems title="Settings" iconClassName="pi pi-cog" />
    <TopbarMenuItems
     title="Profile"
     iconClassName="pi pi-user"
     onClick={(e) => profileMenuRef.current?.toggle(e)}
    />
   </TopbarMenu>

   <Menu ref={profileMenuRef} model={profileItems} popup />
   <LanguageDialog visible={openDialog} onHide={closeDialog} />
  </div>
 );
};

export default AppTopbar;
