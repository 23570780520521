import { AppMenu, AppTopbar } from "@/components";
import { IComponentProps } from "@/models";
import React from "react";
import { MainContainer, MainLayout } from "@/layouts";

interface DashboardWrapperProps extends IComponentProps {}

const DashboardWrapper: React.FC<DashboardWrapperProps> = ({ children }) => {
 return (
  <div>
   <AppTopbar />
   <AppMenu />
   <MainContainer>
    <MainLayout>{children}</MainLayout>
   </MainContainer>
  </div>
 );
};

export default DashboardWrapper;
