import { AddressDataTable, CustomerDetailDataTable } from "@/components";
import { ICustomer } from "@/models";
import { Dialog } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";

interface CustomerDialogProps {
 visible: boolean;
 customer: ICustomer | undefined;
 onHide: () => void;
}

const CustomerAddressDialog: React.FC<CustomerDialogProps> = ({
 visible,
 onHide,
 customer,
}) => {
 const { t } = useTranslation();

 const hideDialog = () => {
  onHide();
 };

 return (
  <Dialog
   visible={visible}
   style={{ width: "80%" }}
   header={t("User Info Details")}
   modal
   className="p-fluid"
   onHide={hideDialog}
  >
   <div className="datatable-crud">
    <div className="card">
     <CustomerDetailDataTable customer={customer} />
    </div>
   </div>

   <div className="datatable-crud">
    <div className="card">
     <AddressDataTable customerId={customer?.id ?? null} />
    </div>
   </div>
  </Dialog>
 );
};

export default CustomerAddressDialog;
