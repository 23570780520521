import { deleteProductImage } from "@/services";
import { useMutation, useQueryClient } from "react-query";
import { Toast } from "primereact";
import { toastError, toastSuccess } from "@/utils";

export const useDeleteProductImageData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const { mutate, isLoading: deleteProductImageIsLoading } = useMutation(
  async (imageId: bigint | number) => await deleteProductImage(imageId),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("product");
    toastSuccess(toast, "Product Image Deleted");
   },
   onError: () => {
    toastError(toast, "Product Image not Deleted");
   },
  },
 );
 return {
  mutate,
  deleteProductImageIsLoading,
 };
};
