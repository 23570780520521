import React, { useEffect, useRef, useState } from "react";
import {
 CampaignDialog,
 CampaignSortDialog,
 DataTableActionTemplate,
 DataTableCrud,
 DataTableDeleteDialog,
 DataTableSearchHeader,
 DataTableStatusTemplate,
 DataTableToolbar,
 ImageDialog,
} from "@/components";
import {
 Button,
 Column,
 ColumnFilterElementTemplateOptions,
 Dropdown,
 InputNumber,
 Toast,
 Tooltip,
} from "primereact";
import {
 useGetCampaignsData,
 useAddCampaignData,
 useUpdateCampaignData,
 useDeleteCampaignData,
 useAddCampaignImagesData,
 useDeleteCampaignImageData,
 useOrderCampaignsData,
} from "@/hooks";
import { ICampaign } from "@/models";
import { initialCampaign } from "../../../../constants/initialValues";
import { campaignFilters, STATUS, statusTypes } from "@/constants";
import { useTranslation } from "react-i18next";

const CampaignDataTable = () => {
 const [campaign, setCampaign] =
  useState<Omit<ICampaign, "imageUrl">>(initialCampaign);
 const [deleteCampaignDialog, setDeleteCampaignDialog] = useState(false);
 const [imageDialog, setImageDialog] = useState(false);
 const [campaignDialog, setCampaignDialog] = useState(false);
 const [campaignSortDialog, setCampaignSortDialog] = useState(false);
 const [isAddItem, setIsAddItem] = useState(false);
 const [fileList, setFileList] = useState<File[]>([]);
 const [filters, setFilters] = useState(campaignFilters);

 const [selectedCampaign, setSelectedCampaign] = useState<ICampaign | null>(
  null,
 );
 const toast = useRef<Toast>(null);
 const { t } = useTranslation();

 const formData = new FormData();

 const { campaigns, getCampaignsIsLoading } = useGetCampaignsData();
 const {
  mutate: addCampaignMutate,
  addCampaignIsLoading,
  addCampaignIsSuccess,
 } = useAddCampaignData(toast);
 const {
  mutate: updateCampaignMutate,
  updateCampaignIsLoading,
  updateCampaignIsSuccess,
 } = useUpdateCampaignData(toast);
 const { mutate: deleteCampaignMutate } = useDeleteCampaignData(toast);
 const { mutate: addCampaignImagesMutate } = useAddCampaignImagesData(toast);
 const { mutate: deleteCampaignImageMutate } =
  useDeleteCampaignImageData(toast);
 const { mutate: orderCampaignMutate } = useOrderCampaignsData(toast);

 useEffect(() => {
  if (addCampaignIsSuccess || updateCampaignIsSuccess) {
   hideCampaignDialog();
  }
 }, [addCampaignIsSuccess, updateCampaignIsSuccess]);

 const openDeleteCampaignDialog = (campaign?: ICampaign) => {
  campaign && setCampaign(campaign);
  setDeleteCampaignDialog(true);
 };

 const openAddCampaignDialog = () => {
  setCampaign(initialCampaign);
  setIsAddItem(true);
  setCampaignDialog(true);
 };

 const hideDeleteCampaignDialog = () => {
  setDeleteCampaignDialog(false);
 };

 const hideImageDialog = () => {
  setImageDialog(false);
 };

 const openImageCampaigntDialog = (rowData: ICampaign) => {
  setSelectedCampaign({ ...rowData });
  setImageDialog(true);
 };

 const openCampaignSortDialog = () => {
  setCampaignSortDialog(true);
 };

 const deleteCampaign = () => {
  campaign.id && deleteCampaignMutate(campaign.id);
  hideDeleteCampaignDialog();
 };

 const openEditCampaignDialog = (rowData: ICampaign) => {
  const { imageUrl, ...rest } = rowData;
  setCampaign({ ...rest });
  setIsAddItem(false);
  setCampaignDialog(true);
 };

 const saveCampaign = (newCampaign: Omit<ICampaign, "imageUrl">) => {
  isAddItem
   ? addCampaignMutate(newCampaign)
   : updateCampaignMutate(newCampaign);
 };

 const hideCampaignDialog = () => {
  setCampaignDialog(false);
 };

 const saveImage = () => {
  formData.append("campaignId", campaign.id?.toString() ?? "");

  formData.append("file", fileList[0]);
  addCampaignImagesMutate(formData);
  setFileList([]);
 };

 return (
  <>
   <Toast ref={toast} />

   <DataTableToolbar
    disableDeleteButton={!selectedCampaign}
    openDeleteDialog={openDeleteCampaignDialog}
    openAddDialog={openAddCampaignDialog}
   >
    <Button
     id="p-sort-button"
     label="Sort"
     icon="pi pi-sort"
     className="p-button-primary mr-2"
     onClick={openCampaignSortDialog}
    />
   </DataTableToolbar>
   <DataTableCrud
    value={campaigns}
    title="campaigns"
    selection={selectedCampaign}
    filters={filters}
    filterDisplay="row"
    globalFilterFields={[
     "name",
     "description",
     "limitValue",
     "bonus",
     "status",
    ]}
    emptyMessage={t("No campaigns found.")}
    loading={getCampaignsIsLoading}
    onSelectionChange={(e: any) => setSelectedCampaign(e.value)}
    header={
     <DataTableSearchHeader
      title={"Manage Campaigns"}
      filters={filters}
      onChange={(e: any) => setFilters(e)}
     />
    }
   >
    <Column
     selectionMode="single"
     headerStyle={{ width: "3rem" }}
     exportable={false}
    ></Column>
    <Column
     field="name"
     header={"Name"}
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder={t("Search by name")}
    ></Column>
    <Column
     field="description"
     header={"Description"}
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder={t("Search by description")}
    ></Column>
    <Column
     field="limitValue"
     header={"limitValue"}
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder={t("Search by limitValue")}
     filterElement={(option: ColumnFilterElementTemplateOptions) => {
      return (
       <InputNumber
        id="limitValue"
        placeholder="Search by limit value"
        type="text"
        onChange={(e) => option.filterApplyCallback(e.value?.toString() ?? "")}
       />
      );
     }}
    ></Column>
    <Column
     field="bonus"
     header={"Bonus"}
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder={t("Search by bonus")}
     filterElement={(option: ColumnFilterElementTemplateOptions) => {
      return (
       <InputNumber
        id="bonus"
        placeholder="Search by bonus"
        type="text"
        onChange={(e) => option.filterApplyCallback(e.value?.toString() ?? "")}
       />
      );
     }}
    ></Column>

    <Column
     field="status"
     header={"Status"}
     sortable
     body={(rowData: ICampaign) => (
      <DataTableStatusTemplate status={rowData.status} />
     )}
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder="Search by status"
     filterElement={(options: ColumnFilterElementTemplateOptions) => {
      return (
       <Dropdown
        value={options.value}
        options={statusTypes}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={(option: string) => {
         return (
          <span
           className={`badge status-${option ? option.toLowerCase() : ""}`}
          >
           {option}
          </span>
         );
        }}
        valueTemplate={(option: string) => {
         return (
          <span
           className={`badge status-${option ? option.toLowerCase() : ""}`}
          >
           {option}
          </span>
         );
        }}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
        style={{ minWidth: "13rem" }}
       />
      );
     }}
    ></Column>

    <Column
     body={(rowData: ICampaign) => (
      <>
       <DataTableActionTemplate
        openDeleteDialog={() => openDeleteCampaignDialog(rowData)}
        openEditDialog={() => openEditCampaignDialog(rowData)}
       />
       <Tooltip target="#p-images-button" position="bottom" content="Images" />
       <Tooltip
        target="#p-sort-button"
        position="bottom"
        content="Sort Campaigns"
       />
       <Button
        id="p-images-button"
        icon="pi pi-images"
        className="p-button-rounded m-2"
        onClick={() => openImageCampaigntDialog(rowData)}
       />
      </>
     )}
     exportable={false}
     style={{ minWidth: "12rem" }}
    ></Column>
   </DataTableCrud>

   <DataTableDeleteDialog
    visible={deleteCampaignDialog}
    data={selectedCampaign ?? campaign}
    onHide={hideDeleteCampaignDialog}
    onDelete={deleteCampaign}
   />

   <CampaignDialog
    visible={campaignDialog}
    campaign={campaign}
    isLoading={isAddItem ? addCampaignIsLoading : updateCampaignIsLoading}
    onHide={hideCampaignDialog}
    addCampaign={saveCampaign}
   />

   <CampaignSortDialog
    visible={campaignSortDialog}
    campaigns={
     campaigns
      ?.filter((campaign) => campaign.status === STATUS.ACTIVE)
      .sort((a, b) => a.sequence - b.sequence) ?? []
    }
    isLoading={getCampaignsIsLoading}
    onHide={() => setCampaignSortDialog(false)}
    orderCampaigns={orderCampaignMutate}
   />

   <ImageDialog
    visible={imageDialog}
    onHide={hideImageDialog}
    addImage={saveImage}
    fileList={fileList}
    setFileList={setFileList}
    multiple={false}
    data={selectedCampaign}
    deleteImage={deleteCampaignImageMutate}
   />
  </>
 );
};

export default CampaignDataTable;
