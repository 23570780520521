import { axiosInstance } from "@/api/axios.instance";
import {
 IAdsCountWithDate,
 ICustomer,
 ICustomerCount,
 IOrder,
 IProduct,
} from "@/models";
import { QueryFunctionContext } from "react-query";

export const getOrderList = async (): Promise<IOrder[]> =>
 await axiosInstance.get(`analytics/order/countbynew`).then((res) => res.data);

export const getCustomerCount = async (): Promise<ICustomerCount> =>
 await axiosInstance.get(`analytics/customers/count`).then((res) => res.data);

export const getTopTenCustomers = async (): Promise<ICustomer[]> =>
 await axiosInstance.get(`analytics/customers/topten`).then((res) => res.data);

export const getQuantityProducts = async (): Promise<IProduct[]> =>
 await axiosInstance
  .get(`analytics/products/listbyquantity`)
  .then((res) => res.data);

export const getAdsCountWithDate = async (): Promise<IAdsCountWithDate> =>
 await axiosInstance
  .get(`/analytics/customers/dailyadsview`)
  .then((res) => res.data);

export const getAdsWatchCountPerDay = async (): Promise<any> =>
 await axiosInstance
  .get(`/analytics/ads/watchcountperday`)
  .then((res) => res.data);

export const getCustomerCountOneMonth = async (): Promise<any> =>
 await axiosInstance
  .get(`/analytics/customers/countonemounth`)
  .then((res) => res.data);

export const getCountWithMoneyBetween = async ({
 queryKey,
}: QueryFunctionContext<
 [
  string,
  {
   moneyAmountFirst: number;
   moneyAmountSecond: number;
  },
 ]
>): Promise<any> => {
 const [, { moneyAmountFirst, moneyAmountSecond }] = queryKey;
 return await axiosInstance
  .get(
   `customer/countwithmoneybetween?moneyAmountFirst=${moneyAmountFirst}&moneyAmountSecond=${moneyAmountSecond}`,
  )
  .then((res) => res.data);
};
