import { Column, Toast } from "primereact";
import React, { useEffect, useRef, useState } from "react";
import {
 DataTableSearchHeader,
 DataTableCrud,
 DataTableActionTemplate,
 DataTableToolbar,
 DataTableDeleteDialog,
 UserDialog,
} from "@/components";
import { IUser } from "@/models";
import { initialUser, userFilters } from "@/constants";
import { useAddUserData, useDeleteUserData, useGetUsersData } from "@/hooks";
import { useTranslation } from "react-i18next";

const UserDataTable = () => {
 const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
 const [filters, setFilters] = useState(userFilters);
 const [user, setUser] = useState<IUser>(initialUser);
 const [deleteUserDialog, setDeleteUserDialog] = useState(false);
 const [userDialog, setUserDialog] = useState(false);

 const toast = useRef<Toast>(null);

 const {
  mutate: addUserMutate,
  addUserIsLoading,
  addUserIsSuccess,
 } = useAddUserData(toast);
 const { mutate: deleteUserMutate } = useDeleteUserData(toast);
 const { users, getUsersIsLoading } = useGetUsersData();

 const { t } = useTranslation();

 useEffect(() => {
  if (addUserIsSuccess) {
   hideUserDialog();
  }
 }, [addUserIsSuccess]);

 const openDeleteUserDialog = (user?: IUser) => {
  user && setUser(user);
  setDeleteUserDialog(true);
 };

 const openAddUserDialog = () => {
  setUser(initialUser);
  setUserDialog(true);
 };

 const hideUserDialog = () => {
  setUserDialog(false);
 };

 const hideDeleteUserDialog = () => {
  setDeleteUserDialog(false);
 };

 const saveUser = (newUser: IUser) => {
  addUserMutate(newUser);
 };

 const deleteUser = () => {
  user.id && deleteUserMutate(user.id);
  hideDeleteUserDialog();
 };

 return (
  <>
   <Toast ref={toast} />
   <DataTableToolbar
    disableDeleteButton={!selectedUser}
    openAddDialog={openAddUserDialog}
    openDeleteDialog={openDeleteUserDialog}
   />

   <DataTableCrud
    value={users}
    selection={selectedUser}
    onSelectionChange={(e) => setSelectedUser(e.value)}
    header={
     <DataTableSearchHeader
      title={t("Manage Staff")}
      filters={filters}
      onChange={(e: any) => setFilters(e)}
     />
    }
    filters={filters}
    loading={getUsersIsLoading}
    title="staff"
    emptyMessage={t("No Staff Found")}
   >
    <Column
     selectionMode="single"
     headerStyle={{ width: "3rem" }}
     exportable={false}
    ></Column>
    <Column
     field="name"
     header={t("Name")}
     sortable
     style={{ minWidth: "12rem" }}
    ></Column>
    <Column
     field="email"
     header={t("Email")}
     sortable
     style={{ minWidth: "12rem" }}
    ></Column>
    <Column
     field="role"
     header={t("Role")}
     sortable
     style={{ minWidth: "12rem" }}
     body={(rowData: IUser) => (
      <span
       className={`badge status-${
        rowData.role ? rowData.role.toLowerCase() : ""
       }`}
      >
       {t(rowData.role)}
      </span>
     )}
    ></Column>
    <Column
     body={(rowData: IUser) => (
      <DataTableActionTemplate
       openDeleteDialog={() => openDeleteUserDialog(rowData)}
      />
     )}
     exportable={false}
     style={{ minWidth: "12rem" }}
    ></Column>
   </DataTableCrud>

   <DataTableDeleteDialog
    visible={deleteUserDialog}
    data={selectedUser ?? user}
    onHide={hideDeleteUserDialog}
    onDelete={deleteUser}
   />

   <UserDialog
    visible={userDialog}
    user={user}
    isLoading={addUserIsLoading}
    onHide={hideUserDialog}
    addUser={saveUser}
   />
  </>
 );
};

export default UserDataTable;
