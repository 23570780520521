import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { TRANSLATIONS_EN } from "./en/translation";
import { TRANSLATIONS_TR } from "./tr/translation";
import { TRANSLATIONS_UR } from "./ur/translation";

i18next
 .use(initReactI18next)
 .use(LanguageDetector)
 .init({
  debug: false,
  fallbackLng: "en",
  resources: {
   en: {
    translation: TRANSLATIONS_EN,
   },
   tr: {
    translation: TRANSLATIONS_TR,
   },
   ur: {
    translation: TRANSLATIONS_UR,
   },
  },
 });
