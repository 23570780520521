import { auth } from "@/config/firebase";
import { logoutUser } from "@/services";

export const profileItems = [
 {
  label: "Profile",
  icon: "pi pi-user",
  url: "profile",
 },
 {
  separator: true,
 },
 {
  label: "Log Out",
  icon: "pi pi-sign-out",
  command: async () => await logoutUser(auth),
 },
];
