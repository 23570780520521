import {
 DashboardCard,
 DashboardHeader,
 NewOrderAnalyticCard,
 ProductQuantityAnalyticCard,
} from "@/components";
import { useGetOrderListData, useGetOrdersData, useRole } from "@/hooks";
import { useTranslation } from "react-i18next";
import { Col, Grid } from "@/layouts";

const OperatorDashboard = () => {
 const { orderList } = useGetOrderListData();
 const { orders, getOrdersIsLoading } = useGetOrdersData();
 const { t } = useTranslation();
 const { role } = useRole();

 return (
  <>
   <Grid>
    <Col className="col-12 flex align-items-center">
     <DashboardHeader role={role} />
    </Col>

    <Col className="col-12 xl:col-6 p-0">
     <DashboardCard
      title="Orders"
      count={`${orderList?.length ?? 0} New`}
      icon="pi-shopping-cart"
      iconTextColor="text-blue-500"
      iconBgColor="bg-blue-100"
      footerTitle={`${orders?.length ?? 0} Total Orders`}
      footerSubTitle=""
      isLoading={getOrdersIsLoading}
      className="col-12"
     />
     <ProductQuantityAnalyticCard />
    </Col>

    <Col className="col-12 xl:col-6 p-0">
     <NewOrderAnalyticCard />
    </Col>
   </Grid>
  </>
 );
};

export default OperatorDashboard;
