import { IMbi } from "@/models";
import { Column, DataTable, Dialog } from "primereact";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataTableDialogFooter, DataTableStatusTemplate } from "@/components";

interface MbiSortDialogProps {
 visible: boolean;
 mbis: Array<Omit<IMbi, "imageUrl">>;
 isLoading: boolean;
 onHide: () => void;
 orderMbis: (mbis: Array<Omit<IMbi, "imageUrl">>) => void;
}

const MbiSortDialog: React.FC<MbiSortDialogProps> = ({
 visible,
 mbis,
 isLoading,
 onHide,
 orderMbis,
}) => {
 const { t } = useTranslation();

 const [sortingMbis, setSortingMbis] = useState<Array<Omit<IMbi, "imageUrl">>>(
  [],
 );

 useEffect(() => {
  setSortingMbis([...mbis]);
 }, [mbis]);

 const hideDialog = () => {
  onHide();
 };

 const handleSubmit = () => {
  orderMbis(sortingMbis);
  hideDialog();
 };

 const handleSortingMbis = (e: any) => {
  const newSortingMbis = e.map(
   (campaign: Omit<IMbi, "imageUrl">, index: number) => {
    return {
     ...campaign,
     sequence: index + 1,
    };
   },
  );
  setSortingMbis([...newSortingMbis]);
 };

 return (
  <Dialog
   visible={visible}
   style={{ minWidth: "450px", width: "80%" }}
   header={t("Mainboard Item Sort")}
   modal
   className="p-fluid"
   footer={
    <DataTableDialogFooter
     loading={isLoading}
     hideDialog={hideDialog}
     handleSubmit={handleSubmit}
    />
   }
   onHide={hideDialog}
  >
   <DataTable
    value={sortingMbis}
    title="campaigns"
    paginator={false}
    loading={isLoading}
    reorderableRows
    onRowReorder={(e) => handleSortingMbis(e.value)}
   >
    <Column rowReorder style={{ width: "3rem" }} />
    <Column
     field="sequence"
     header={"Sequence"}
     style={{ minWidth: "12rem" }}
    ></Column>
    <Column
     field="title"
     header={"Title"}
     style={{ minWidth: "12rem" }}
    ></Column>
    <Column
     field="status"
     header={"Status"}
     body={(rowData: IMbi) => (
      <DataTableStatusTemplate status={rowData.status} />
     )}
     style={{ minWidth: "12rem" }}
    ></Column>
   </DataTable>
  </Dialog>
 );
};

export default MbiSortDialog;
