import { getCategories } from "@/services";
import { useQuery } from "react-query";

export const useGetCategoriesData = (options?: {
 enabled: boolean | undefined;
}) => {
 const {
  data: categories,
  isLoading: getCategoriesIsLoading,
  refetch: getRefetchCategories,
 } = useQuery("categories", getCategories, options);
 return { categories, getCategoriesIsLoading, getRefetchCategories };
};
