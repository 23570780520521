import classNames from "classnames";
import React from "react";

interface TopbarMenuProps {
 children: React.ReactNode;
 active: boolean;
}

const TopbarMenu: React.FC<TopbarMenuProps> = ({ children, active }) => {
 return (
  <ul
   className={classNames("layout-topbar-menu lg:flex origin-top", {
    "layout-topbar-menu-mobile-active": active,
   })}
  >
   {children}
  </ul>
 );
};

export default TopbarMenu;
