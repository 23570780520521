import { IStock } from "@/models";
import { addStock } from "@/services/stock-services/stockServices";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useAddStockData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const {
  mutate,
  isLoading: addStockIsLoading,
  isSuccess: addStockIsSuccess,
 } = useMutation(async (stock: IStock) => await addStock(stock), {
  onSuccess: () => {
   queryClient.invalidateQueries("products");
   toastSuccess(toast, "Stock Added");
  },
  onError: () => {
   toastError(toast, "Stock not Added");
  },
 });
 return {
  mutate,
  addStockIsLoading,
  addStockIsSuccess,
 };
};
