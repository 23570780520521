import { isFormFieldValid } from "@/utils";
import React from "react";

interface ErrorFieldProps {
 formik: any;
 name: string;
}

const ErrorField: React.FC<ErrorFieldProps> = ({ formik, name }) => {
 return (
  <small className="p-error">
   {isFormFieldValid(formik, name) && formik.errors[name]}
  </small>
 );
};

export default ErrorField;
