import { ROLE } from "../enums";

export const getMenuItems = (role: ROLE | undefined) => {
 return [
  {
   label: "Home",
   items: [
    { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/" },
    role !== ROLE.OPERATOR && {
     label: "Users Management",
     icon: "pi pi-fw pi-users",
     to: "/user",
    },
    {
     label: "Products Management",
     icon: "pi pi-fw pi-box",
     items: [
      {
       label: "Products",
       icon: "pi pi-fw pi-box",
       to: "/product",
      },
      { label: "Suppliers", icon: "pi pi-fw pi-users", to: "/supplier" },
      { label: "Categories", icon: "pi pi-fw pi-users", to: "/category" },
     ],
    },

    {
     label: "Campaigns Management",
     icon: "pi pi-fw pi-box",
     to: "/campaign",
    },
    {
     label: "Orders Management",
     icon: "pi pi-fw pi-box",
     items: [
      {
       label: "Active Orders",
       icon: "pi pi-fw pi-box",
       to: "/activeorder",
      },
      {
       label: "In Active Orders",
       icon: "pi pi-fw pi-box",
       to: "/inactiveorder",
      },
     ],
    },
    {
     label: "Main Board Management",
     icon: "pi pi-fw pi-box",
     to: "/mbi",
    },
    role === ROLE.ADMIN && {
     label: "System",
     icon: "pi pi-fw pi-cog",
     items: [
      {
       label: "Staff",
       icon: "pi pi-fw pi-users",
       to: "/stuff",
      },
     ],
    },
   ].filter(Boolean),
  },
 ];
};
