import { DataTable, DataTableProps } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";

interface DataTableCrudProps extends DataTableProps {
 value: any;
 title: string;
 selection?: any;
 loading: boolean;
 header?: React.ReactNode;
 filters?: any;
 onSelectionChange?: (e: any) => void;
}

const DataTableCrud: React.FC<DataTableCrudProps> = ({
 children,
 title,
 ...rest
}) => {
 const { t } = useTranslation();
 return (
  <DataTable
   {...rest}
   dataKey="id"
   paginator
   rows={10}
   rowsPerPageOptions={[5, 10, 25]}
   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
   currentPageReportTemplate={t(
    `Showing {first} to {last} of {totalRecords} ${title}`,
   )}
   responsiveLayout="scroll"
  >
   {children}
  </DataTable>
 );
};

export default DataTableCrud;
