import { useState, useRef } from "react";
import {
 Column,
 Button,
 Toast,
 Dropdown,
 ColumnFilterElementTemplateOptions,
 Tooltip,
} from "primereact";
import {
 DataTableCrud,
 DataTableDeleteDialog,
 DataTableSearchHeader,
 OrderConfirmDialog,
 OrderDetailDialog,
 OrderTrackingNumberDialog,
} from "@/components";
import { useTranslation } from "react-i18next";
import { orderFilters, activeOrderTypes } from "@/constants";
import {
 useGetOrdersData,
 useSetOrderPreparingData,
 useSetOrderSendingData,
 useSetOrderFinishData,
 useSetOrderCancelData,
 useSetOrderReturnData,
} from "@/hooks";
import { IOrder } from "@/models";
import moment from "moment";
import { ORDERS_STATUS } from "@/constants/enums";

const OrderDataTable = () => {
 const [filters, setFilters] = useState(orderFilters);
 const [orderDialog, setOrderDialog] = useState(false);
 const [orderConfirmDialog, setOrderConfirmDialog] = useState(false);
 const [deleteDialog, setDeleteDialog] = useState(false);
 const [deleteType, setDeleteType] = useState<
  ORDERS_STATUS.CANCELED | ORDERS_STATUS.RETURN
 >(ORDERS_STATUS.CANCELED);
 const [trackingNumberDialog, setTrackingNumberDialog] = useState(false);
 const [order, setOrder] = useState<IOrder>();

 const toast = useRef<Toast>(null);

 const { orders, getOrdersIsLoading } = useGetOrdersData();
 const { preparingMutate } = useSetOrderPreparingData(toast);
 const { sentingMutate } = useSetOrderSendingData(toast);
 const { finishMutate } = useSetOrderFinishData(toast);
 const { cancelMutate } = useSetOrderCancelData(toast);
 const { returnMutate } = useSetOrderReturnData(toast);

 const { t } = useTranslation();

 const openOrderDialog = (rowData: IOrder) => {
  setOrder({ ...rowData });
  setOrderDialog(true);
 };

 const hideOrderDialog = () => {
  setOrderDialog(false);
 };

 const openTrackingNumberDialog = (rowData: IOrder) => {
  setOrder({ ...rowData });
  setTrackingNumberDialog(true);
 };

 const openDeleteDialog = (
  rowData: IOrder,
  type: ORDERS_STATUS.CANCELED | ORDERS_STATUS.RETURN,
 ) => {
  setOrder({ ...rowData });
  setDeleteType(type);
  setDeleteDialog(true);
 };

 const setOrderNextStep = (rowData: IOrder) => {
  setOrder({ ...rowData });
  rowData.status === ORDERS_STATUS.PREPARING
   ? openTrackingNumberDialog(rowData)
   : setOrderConfirmDialog(true);
 };

 const confirmOrder = () => {
  if (order) {
   order.status === ORDERS_STATUS.NEW && preparingMutate(order.id);
   order.status === ORDERS_STATUS.SENT && finishMutate(order.id);
  }
  setOrderConfirmDialog(false);
 };

 return (
  <>
   <Toast ref={toast} />

   <DataTableCrud
    value={orders}
    filterDisplay="row"
    header={
     <DataTableSearchHeader
      title={t("Manage Orders")}
      filters={filters}
      onChange={(e) => setFilters(e)}
     />
    }
    filters={filters}
    globalFilterFields={["customerName", "productName", "date", "status"]}
    loading={getOrdersIsLoading}
    title="orders"
    emptyMessage={t("No orders found")}
   >
    <Column field="id" header="Id" style={{ minWidth: "5rem" }} />
    <Column
     field="customerName"
     header="Customer Name"
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder="Search by name"
    />
    <Column
     field="productName"
     header="Product Name"
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder="Search by name"
    />
    <Column
     field="date"
     header="Date"
     dataType="date"
     sortable
     style={{ minWidth: "12rem" }}
     body={(rowData: IOrder) => (
      <>{moment(rowData.date).format("DD/MM/YYYY")}</>
     )}
    />
    <Column
     field="status"
     header="Status"
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder="Search by status"
     filterElement={(options: ColumnFilterElementTemplateOptions) => {
      return (
       <Dropdown
        value={options.value}
        options={activeOrderTypes}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={(option: string) => {
         return (
          <span
           className={`badge status-${option ? option.toLowerCase() : ""}`}
          >
           {option}
          </span>
         );
        }}
        valueTemplate={(option: string) => {
         return (
          <span
           className={`badge status-${option ? option.toLowerCase() : ""}`}
          >
           {option}
          </span>
         );
        }}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
        style={{ minWidth: "13rem" }}
       />
      );
     }}
     body={(rowData: IOrder) => (
      <span
       className={`badge status-${
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        rowData.status ? rowData.status.toLowerCase() : ""
       }`}
      >
       {rowData.status}
      </span>
     )}
    />
    <Column
     body={(rowData: IOrder) => (
      <>
       <Tooltip target="#p-info-button" content="Info" position="bottom" />
       <Tooltip
        target="#p-set-order-button"
        content="Set Next Step"
        position="bottom"
       />
       <Tooltip target="#p-cancel-button" content="Cancel" position="bottom" />
       <Tooltip target="#p-return-button" content="Return" position="bottom" />
       <Button
        id="p-info-button"
        icon="pi pi-info-circle"
        className="p-button-rounded p-button-secondary m-2"
        onClick={() => {
         openOrderDialog(rowData);
        }}
       />
       <Button
        id="p-set-order-button"
        icon="pi pi-arrow-right"
        className="p-button-rounded p-button-info m-2"
        onClick={() => setOrderNextStep(rowData)}
       />
       <Button
        id="p-cancel-button"
        icon="pi pi-times"
        className="p-button-rounded p-button-danger m-2"
        onClick={() => openDeleteDialog(rowData, ORDERS_STATUS.CANCELED)}
       />
       <Button
        id="p-return-button"
        icon="pi pi-undo"
        className="p-button-rounded p-button-warning m-2"
        onClick={() => openDeleteDialog(rowData, ORDERS_STATUS.RETURN)}
       />
      </>
     )}
     exportable={false}
     style={{ minWidth: "12rem" }}
    ></Column>
   </DataTableCrud>

   <OrderDetailDialog
    visible={orderDialog}
    isLoading={getOrdersIsLoading}
    onHide={hideOrderDialog}
    order={order}
   />

   <OrderConfirmDialog
    visible={orderConfirmDialog}
    onHide={() => setOrderConfirmDialog(false)}
    onConfirm={confirmOrder}
    status={order?.status}
   />

   <OrderTrackingNumberDialog
    visible={trackingNumberDialog}
    onHide={() => setTrackingNumberDialog(false)}
    order={order}
    onSave={sentingMutate}
   />

   <DataTableDeleteDialog
    visible={deleteDialog}
    onHide={() => setDeleteDialog(false)}
    onDelete={
     deleteType === ORDERS_STATUS.CANCELED ? cancelMutate : returnMutate
    }
    data={order}
    text={`Are you sure you want to ${
     deleteType === ORDERS_STATUS.CANCELED ? "cancel" : "return"
    } this order`}
   />
  </>
 );
};

export default OrderDataTable;
