import { deleteUser } from "@/services";
import { useMutation, useQueryClient } from "react-query";
import { Toast } from "primereact";
import { toastError, toastSuccess } from "@/utils";

export const useDeleteUserData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const { mutate, isLoading: deleteUserIsLoading } = useMutation(
  async (userId: bigint) => await deleteUser(userId),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("users");
    toastSuccess(toast, "User Deleted");
   },
   onError: () => {
    toastError(toast, "User not Deleted");
   },
  },
 );
 return {
  mutate,
  deleteUserIsLoading,
 };
};
