import React, { useState, useRef, useEffect } from "react";
import {
 DataTableActionTemplate,
 DataTableCrud,
 DataTableDeleteDialog,
 DataTableSearchHeader,
 DataTableToolbar,
 CategoryDialog,
} from "@/components";
import { Column, Toast } from "primereact";
import { categoryFilters, initialCategory } from "@/constants";
import { ICategory } from "@/models";
import {
 useGetCategoriesData,
 useAddCategoryData,
 useDeleteCategoryData,
 useUpdateCategoryData,
} from "@/hooks/category";
import { useTranslation } from "react-i18next";

const CategoryDataTable = () => {
 const [category, setCategory] = useState<ICategory>(initialCategory);
 const [deleteCategoryDialog, setDeleteCategoryDialog] = useState(false);
 const [filters, setFilters] = useState(categoryFilters);
 const [isAddItem, setIsAddItem] = useState(false);
 const [selectedCategory, setSelectedCategory] = useState<any>(null);
 const [categoryDialog, setCategoryDialog] = useState(false);

 const { t } = useTranslation();

 const toast = useRef<Toast>(null);

 const {
  mutate: addCategoryMutate,
  addCategoryIsLoading,
  addCategoryIsSuccess,
 } = useAddCategoryData(toast);
 const {
  mutate: updateCategoryMutate,
  updateCategoryIsLoading,
  updateCategoryIsSuccess,
 } = useUpdateCategoryData(toast);
 const { mutate: deleteCategoryMutate } = useDeleteCategoryData(toast);

 const { categories, getCategoriesIsLoading } = useGetCategoriesData();

 useEffect(() => {
  if (addCategoryIsSuccess || updateCategoryIsSuccess) {
   hideCategoryDialog();
  }
 }, [addCategoryIsSuccess, updateCategoryIsSuccess]);

 const hideCategoryDialog = () => {
  setCategoryDialog(false);
 };

 const hideDeleteCategoryDialog = () => {
  setDeleteCategoryDialog(false);
 };

 const saveCategory = (newCategory: ICategory) => {
  isAddItem
   ? addCategoryMutate(newCategory)
   : updateCategoryMutate(newCategory);
 };

 const deleteCategory = () => {
  category.id && deleteCategoryMutate(category.id);
  hideDeleteCategoryDialog();
 };

 const openDeleteCategoryDialog = (category?: ICategory) => {
  console.log(category);

  category && setCategory(category);
  setDeleteCategoryDialog(true);
 };

 const openAddCategoryDialog = () => {
  setCategory(initialCategory);
  setIsAddItem(true);
  setCategoryDialog(true);
 };

 const openEditCategoryDialog = (rowData: ICategory) => {
  setCategory({ ...rowData });
  setIsAddItem(false);
  setCategoryDialog(true);
 };

 return (
  <>
   <Toast ref={toast} />
   <DataTableToolbar
    disableDeleteButton={!selectedCategory}
    openDeleteDialog={openDeleteCategoryDialog}
    openAddDialog={openAddCategoryDialog}
   />

   <DataTableCrud
    value={categories}
    title="categories"
    selection={selectedCategory}
    filters={filters}
    loading={getCategoriesIsLoading}
    onSelectionChange={(e: any) => setSelectedCategory(e.value)}
    header={
     <DataTableSearchHeader
      title={t("Manage Categories")}
      filters={filters}
      onChange={(e: any) => setFilters(e)}
     />
    }
   >
    <Column
     selectionMode="single"
     headerStyle={{ width: "3rem" }}
     exportable={false}
    ></Column>
    <Column
     field="title"
     header={"Title"}
     sortable
     style={{ minWidth: "12rem" }}
    ></Column>
    <Column
     field="description"
     header={"Description"}
     sortable
     style={{ minWidth: "12rem" }}
    ></Column>
    <Column
     body={(rowData: ICategory) => (
      <DataTableActionTemplate
       openDeleteDialog={() => openDeleteCategoryDialog(rowData)}
       openEditDialog={() => openEditCategoryDialog(rowData)}
      />
     )}
     exportable={false}
     style={{ minWidth: "12rem" }}
    ></Column>
   </DataTableCrud>

   <DataTableDeleteDialog
    visible={deleteCategoryDialog}
    data={selectedCategory ?? category}
    onHide={hideDeleteCategoryDialog}
    onDelete={deleteCategory}
   />

   <CategoryDialog
    visible={categoryDialog}
    category={category}
    isLoading={isAddItem ? addCategoryIsLoading : updateCategoryIsLoading}
    onHide={hideCategoryDialog}
    addCategory={saveCategory}
   />
  </>
 );
};

export default CategoryDataTable;
