import { axiosInstance } from "@/api/axios.instance";
import { ISupplier } from "@/models";

export const getSuppliers = async (): Promise<ISupplier[]> =>
 await axiosInstance.get(`/suppliers`).then((res) => res.data);

export const addSupplier = async (supplier: ISupplier) =>
 await axiosInstance.post(`/suppliers`, supplier);

export const updateSupplier = async (supplier: ISupplier) =>
 await axiosInstance.put(`/suppliers`, supplier);

export const deleteSupplier = async (supplierId: bigint) =>
 await axiosInstance.delete(`/suppliers/${supplierId}`);
