import { IComponentProps } from "@/models";
import React from "react";

interface AuthWrapperProps extends IComponentProps {}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
 return <div className="layout-auth-container">{children}</div>;
};

export default AuthWrapper;
