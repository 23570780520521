import { GENDER } from "@/constants";
import { ORDERS_STATUS, ROLE, STATUS } from "./enums";

export const customerRadioButtonFields = [GENDER.MAN, GENDER.WOMAN];
export const statusRadioButtonFields = [STATUS.ACTIVE, STATUS.INACTIVE];

export const supplierTypes = ["STANDART"];
export const roleTypes = [ROLE.ADMIN, ROLE.MANAGER, ROLE.OPERATOR];
export const activeOrderTypes = [
 ORDERS_STATUS.NEW,
 ORDERS_STATUS.PREPARING,
 ORDERS_STATUS.SENT,
];

export const inActiveOrderTypes = [
 ORDERS_STATUS.FINISHED,
 ORDERS_STATUS.CANCELED,
 ORDERS_STATUS.RETURN,
];
export const statusTypes = [STATUS.ACTIVE, STATUS.INACTIVE];
export const customerStatusTypes = [STATUS.ACTIVE, STATUS.INACTIVE, "DELETED"];
