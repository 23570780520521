import { Column, DataTable, Dialog } from "primereact";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICampaign } from "@/models";
import { DataTableDialogFooter, DataTableStatusTemplate } from "@/components";

interface CampaignSortDialogProps {
 visible: boolean;
 campaigns: Array<Omit<ICampaign, "imageUrl">>;
 isLoading: boolean;
 onHide: () => void;
 orderCampaigns: (campaigns: Array<Omit<ICampaign, "imageUrl">>) => void;
}

const CampaignSortDialog: React.FC<CampaignSortDialogProps> = ({
 visible,
 campaigns,
 isLoading,
 onHide,
 orderCampaigns,
}) => {
 const { t } = useTranslation();

 const [sortingCampaigns, setSortingCampaigns] = useState<
  Array<Omit<ICampaign, "imageUrl">>
 >([]);

 useEffect(() => {
  setSortingCampaigns([...campaigns]);
 }, [campaigns]);

 const hideDialog = () => {
  onHide();
 };

 const handleSubmit = () => {
  orderCampaigns(sortingCampaigns);
  hideDialog();
 };

 const handleSortingCampaigns = (e: any) => {
  const newSortingCampaigns = e.map(
   (campaign: Omit<ICampaign, "imageUrl">, index: number) => {
    return {
     ...campaign,
     sequence: index + 1,
    };
   },
  );
  setSortingCampaigns([...newSortingCampaigns]);
 };

 return (
  <Dialog
   visible={visible}
   style={{ minWidth: "450px", width: "80%" }}
   header={t("Campaign Sort")}
   modal
   className="p-fluid"
   footer={
    <DataTableDialogFooter
     loading={isLoading}
     hideDialog={hideDialog}
     handleSubmit={handleSubmit}
    />
   }
   onHide={hideDialog}
  >
   <DataTable
    value={sortingCampaigns}
    title="campaigns"
    paginator={false}
    loading={isLoading}
    reorderableRows
    onRowReorder={(e) => handleSortingCampaigns(e.value)}
   >
    <Column rowReorder style={{ width: "3rem" }} />
    <Column
     field="sequence"
     header={"Sequence"}
     style={{ minWidth: "12rem" }}
    ></Column>
    <Column field="name" header={"Name"} style={{ minWidth: "12rem" }}></Column>
    <Column
     field="status"
     header={"Status"}
     body={(rowData: ICampaign) => (
      <DataTableStatusTemplate status={rowData.status} />
     )}
     style={{ minWidth: "12rem" }}
    ></Column>
   </DataTable>
  </Dialog>
 );
};

export default CampaignSortDialog;
