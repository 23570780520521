import { OrderDataTable } from "@/components";
import React from "react";

const Orders = () => {
 return (
  <div className="datatable-crud">
   <div className="card">
    <OrderDataTable />
   </div>
  </div>
 );
};

export default Orders;
