import { auth } from "@/config/firebase";
import { loginUser } from "@/services";
import classNames from "classnames";
import { ErrorField, InputField } from "@/components";
import { Button } from "primereact";
import { useFormik } from "formik";
import { capitalizeFirstLetter, isFormFieldValid } from "@/utils";
import { loginSchema } from "@/validations";
import { initialLogin } from "@/constants";
import { useNavigate } from "react-router-dom";

const Login = () => {
 const navigate = useNavigate();

 const formik = useFormik({
  initialValues: initialLogin,
  validationSchema: loginSchema,
  onSubmit: ({ email, password }) => {
   loginUser(auth, email, password);
   navigate("/");
  },
 });

 return (
  <div className="col-12 lg:col-6 xl:col-4">
   <div className="card">
    <form onSubmit={formik.handleSubmit} className="p-fluid">
     <InputField
      id="email"
      value={formik.values.email ?? ""}
      type="email"
      autoFocus={true}
      onChange={formik.handleChange}
      labelName="email"
      labelText={capitalizeFirstLetter("email")}
      getFormErrorMessage={() => <ErrorField formik={formik} name={"email"} />}
      className={classNames({
       "p-invalid": isFormFieldValid(formik, "email"),
      })}
     />

     <InputField
      id="password"
      value={formik.values.password ?? ""}
      type="password"
      onChange={formik.handleChange}
      labelName="password"
      labelText={capitalizeFirstLetter("password")}
      getFormErrorMessage={() => (
       <ErrorField formik={formik} name={"password"} />
      )}
      className={classNames({
       "p-invalid": isFormFieldValid(formik, "password"),
      })}
     />
     <Button type="submit" label="Submit" className="mt-2" />
    </form>
   </div>
  </div>
 );
};

export default Login;
