import { ICampaign } from "@/models";
import { addCampaign } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useAddCampaignData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const {
  mutate,
  isLoading: addCampaignIsLoading,
  isSuccess: addCampaignIsSuccess,
 } = useMutation(
  async (campaign: Omit<ICampaign, "imageUrl">) => await addCampaign(campaign),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("campaigns");
    toastSuccess(toast, "Campaign Add");
   },
   onError: () => {
    toastError(toast, "Campaign not Add");
   },
  },
 );
 return {
  mutate,
  addCampaignIsLoading,
  addCampaignIsSuccess,
 };
};
