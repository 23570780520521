import { useState, useRef } from "react";
import {
 Column,
 Button,
 Toast,
 Dropdown,
 ColumnFilterElementTemplateOptions,
} from "primereact";
import { DataTableCrud, DataTableSearchHeader } from "@/components";
import { useTranslation } from "react-i18next";
import { orderFilters, inActiveOrderTypes } from "@/constants";
import { useGetInActiveOrdersData } from "@/hooks";
import { IOrder } from "@/models";
import moment from "moment";

const InActiveOrderDataTable = () => {
 const [filters, setFilters] = useState(orderFilters);
 const toast = useRef<Toast>(null);
 const { orders, getInActiveOrdersIsLoading } = useGetInActiveOrdersData();
 const { t } = useTranslation();

 return (
  <>
   <Toast ref={toast} />

   <DataTableCrud
    value={orders}
    filterDisplay="row"
    header={
     <DataTableSearchHeader
      title={t("Manage In Active Orders")}
      filters={filters}
      onChange={(e) => setFilters(e)}
     />
    }
    filters={filters}
    globalFilterFields={["customerName", "productName", "date", "status"]}
    loading={getInActiveOrdersIsLoading}
    title="orders"
    emptyMessage={t("No orders found")}
   >
    <Column field="id" header="Id" style={{ minWidth: "5rem" }} />
    <Column
     field="customerName"
     header="Customer Name"
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder="Search by name"
    />
    <Column
     field="productName"
     header="Product Name"
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder="Search by name"
    />
    <Column
     field="date"
     header="Date"
     dataType="date"
     sortable
     style={{ minWidth: "12rem" }}
     body={(rowData: IOrder) => (
      <>{moment(rowData.date).format("DD/MM/YYYY")}</>
     )}
    />
    <Column
     field="status"
     header="Status"
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder="Search by status"
     filterElement={(options: ColumnFilterElementTemplateOptions) => {
      return (
       <Dropdown
        value={options.value}
        options={inActiveOrderTypes}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={(option: string) => {
         return (
          <span
           className={`badge status-${option ? option.toLowerCase() : ""}`}
          >
           {option}
          </span>
         );
        }}
        valueTemplate={(option: string) => {
         return (
          <span
           className={`badge status-${option ? option.toLowerCase() : ""}`}
          >
           {option}
          </span>
         );
        }}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
        style={{ minWidth: "13rem" }}
       />
      );
     }}
     body={(rowData: IOrder) => (
      <span
       className={`badge status-${
        rowData.status ? rowData.status.toLowerCase() : ""
       }`}
      >
       {rowData.status}
      </span>
     )}
    />
   </DataTableCrud>
  </>
 );
};

export default InActiveOrderDataTable;
