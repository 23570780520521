import { ISupplier } from "@/models";
import { addSupplier } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useAddSupplierData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const {
  mutate,
  isLoading: addSupplierIsLoading,
  isSuccess: addSupplierIsSuccess,
 } = useMutation(async (supplier: ISupplier) => await addSupplier(supplier), {
  onSuccess: () => {
   queryClient.invalidateQueries("suppliers");
   toastSuccess(toast, "Supplier Added");
  },
  onError: () => {
   toastError(toast, "Supplier not Added");
  },
 });
 return {
  mutate,
  addSupplierIsLoading,
  addSupplierIsSuccess,
 };
};
