import * as Yup from "yup";

export const userSchema = Yup.object().shape({
 email: Yup.string().email().required("email is field required"),
 password: Yup.string()
  // .min(8, "Please enter at least 8 characters!")
  // .minLowercase(1, "password must contain at least 1 lower case letter")
  // .minUppercase(1, "password must contain at least 1 upper case letter")
  // .minNumbers(1, "password must contain at least 1 number")
  // .minSymbols(1, "password must contain at least 1 special character")
  .max(50, "You can enter up to 50 characters!")
  .required("password field is required"),
 name: Yup.string()
  .min(2, "Please enter at least 2 characters!")
  .max(50, "You can enter up to 50 characters!")
  .required("name is field required"),
 type: Yup.string()
  .min(2, "Please enter at least 2 characters!")
  .max(50, "You can enter up to 50 characters!")
  .required("type is field required"),
});
