import { MbiDataTable } from "@/components";
import React from "react";

const Mbi = () => {
 return (
  <div className="datatable-crud">
   <div className="card">
    <MbiDataTable />
   </div>
  </div>
 );
};

export default Mbi;
