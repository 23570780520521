import { Toast } from "primereact";

export const toastSuccess = (
 toast: React.RefObject<Toast>,
 message: string,
 life?: number,
) => {
 toast.current?.show({
  severity: "success",
  summary: "Success",
  detail: message,
  life: life ?? 3000,
 });
};

export const toastError = (
 toast: React.RefObject<Toast>,
 message: string,
 life?: number,
) => {
 toast.current?.show({
  severity: "error",
  summary: "Error",
  detail: message,
  life: life ?? 3000,
 });
};

export const toastWarning = (
 toast: React.RefObject<Toast>,
 message: string,
 life?: number,
) => {
 toast.current?.show({
  severity: "warn",
  summary: "Warning",
  detail: message,
  life: life ?? 3000,
 });
};

export const toastInfo = (
 toast: React.RefObject<Toast>,
 message: string,
 life?: number,
) => {
 toast.current?.show({
  severity: "info",
  summary: "Info",
  detail: message,
  life: life ?? 3000,
 });
};
