import {
 ErrorField,
 InputField,
 DataTableDialogFooter,
 DropdownField,
} from "@/components";
import { roleTypes } from "@/constants";
import { IUser } from "@/models";
import { capitalizeFirstLetter, isFormFieldValid } from "@/utils";
import { userSchema } from "@/validations";
import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";

interface UserDialogProps {
 visible: boolean;
 user: IUser;
 isLoading: boolean;
 onHide: () => void;
 addUser: (user: IUser) => void;
}

const roleOptions = roleTypes?.map((type) => ({
 label: type,
 value: type,
}));

const UserDialog: React.FC<UserDialogProps> = ({
 visible,
 user,
 isLoading,
 onHide,
 addUser,
}) => {
 const { t } = useTranslation();

 const hideDialog = () => {
  onHide();
  formik.resetForm();
 };

 const formik = useFormik({
  initialValues: user,
  enableReinitialize: true,
  validationSchema: userSchema,
  onSubmit: (data) => {
   addUser(data);
  },
 });

 return (
  <Dialog
   visible={visible}
   style={{ width: "450px" }}
   header={t("Stuff Details")}
   modal
   className="p-fluid"
   footer={
    <DataTableDialogFooter
     hideDialog={hideDialog}
     handleSubmit={formik.handleSubmit}
     loading={isLoading}
    />
   }
   onHide={hideDialog}
  >
   <form onSubmit={formik.handleSubmit}>
    <InputField
     id="email"
     value={formik.values.email ?? ""}
     type="email"
     required
     autoFocus={true}
     onChange={formik.handleChange}
     labelName="email"
     labelText={capitalizeFirstLetter("email")}
     getFormErrorMessage={() => <ErrorField formik={formik} name={"email"} />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "email"),
     })}
    />
    <InputField
     id="password"
     value={formik.values.password ?? ""}
     type="password"
     required
     onChange={formik.handleChange}
     labelName="password"
     labelText={capitalizeFirstLetter("password")}
     getFormErrorMessage={() => (
      <ErrorField formik={formik} name={"password"} />
     )}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "password"),
     })}
    />
    <InputField
     id="name"
     value={formik.values.name ?? ""}
     type="text"
     required
     onChange={formik.handleChange}
     labelName="name"
     labelText={capitalizeFirstLetter("name")}
     getFormErrorMessage={() => <ErrorField formik={formik} name={"name"} />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "name"),
     })}
    />
    <InputField
     id="type"
     value={formik.values.type ?? ""}
     type="text"
     required
     onChange={formik.handleChange}
     labelName="type"
     labelText={capitalizeFirstLetter("type")}
     getFormErrorMessage={() => <ErrorField formik={formik} name={"type"} />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "type"),
     })}
    />

    <DropdownField
     id="role"
     name="role"
     value={formik.values.role}
     onChange={formik.handleChange}
     options={roleOptions ?? []}
     labelName="role"
     labelText={capitalizeFirstLetter("role")}
     getFormErrorMessage={() => <ErrorField formik={formik} name="role" />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "role"),
     })}
    />
   </form>
  </Dialog>
 );
};

export default UserDialog;
