import { SupplierDataTable } from "@/components";
import React from "react";

const Suppliers = () => {
 return (
  <div className="datatable-crud">
   <div className="card">
    <SupplierDataTable />
   </div>
  </div>
 );
};

export default Suppliers;
