import { useGetCustomerCountOneMonthData } from "@/hooks";
import { Chart } from "primereact";
import React, { useEffect, useState } from "react";

const CustomerCountOneMonthChart = () => {
 const [chartData, setChartData] = useState({});
 const [chartOptions, setChartOptions] = useState({});
 const { customerCountOneMonth, getCustomerCountOneMonthIsLoading } =
  useGetCustomerCountOneMonthData();

 useEffect(() => {
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");
  const textColorSecondary = documentStyle.getPropertyValue(
   "--text-color-secondary",
  );
  const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
  const data = customerCountOneMonth?.map((item: any) => item[1]);
  const labels = customerCountOneMonth?.map((item: any) => item[0]);
  const chartData = {
   labels: labels ?? [],
   datasets: [
    {
     label: "Active Customer Count Per Month",
     data: data ?? [],
     fill: false,
     borderColor: documentStyle.getPropertyValue("--green-500"),
     tension: 0.4,
     fontSize: 20,
    },
   ],
  };
  const options = {
   maintainAspectRatio: false,
   aspectRatio: 0.6,
   plugins: {
    legend: {
     labels: {
      color: textColor,
     },
    },
   },
   scales: {
    x: {
     ticks: {
      color: textColorSecondary,
     },
     grid: {
      color: surfaceBorder,
     },
    },
    y: {
     ticks: {
      color: textColorSecondary,
     },
     grid: {
      color: surfaceBorder,
     },
    },
   },
  };

  setChartData(chartData);
  setChartOptions(options);
 }, [getCustomerCountOneMonthIsLoading]);

 return (
  <div className="card mb-0">
   <Chart type="line" data={chartData} options={chartOptions} />
  </div>
 );
};

export default CustomerCountOneMonthChart;
