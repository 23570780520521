import React from "react";

interface Props {
 id?: string;
 className?: string;
 style?: React.CSSProperties;
 children?: React.ReactNode;
}

const Grid: React.FC<Props> = ({ children, className, ...rest }) => {
 return (
  <div className={`grid ${className ?? ""}`} {...rest}>
   {children}
  </div>
 );
};

export default Grid;
