import React from "react";
import { Button, Dialog } from "primereact";
import { useTranslation } from "react-i18next";

interface DataTableDeleteDialogProps {
 visible: boolean;
 data: any;
 onHide: () => void;
 onDelete: (id: any) => void;
 text?: string;
}

const DataTableDeleteDialog: React.FC<DataTableDeleteDialogProps> = ({
 visible,
 data,
 onHide,
 onDelete,
 text,
}) => {
 const { t } = useTranslation();
 const deleteDialogFooter = (
  <>
   <Button
    label={t("No")}
    icon="pi pi-times"
    className="p-button-text"
    onClick={onHide}
   />
   <Button
    label={t("Yes")}
    icon="pi pi-check"
    className="p-button-text"
    onClick={() => onDelete(data.id)}
   />
  </>
 );

 return (
  <Dialog
   visible={visible}
   style={{ width: "450px" }}
   header="Confirm"
   modal
   footer={deleteDialogFooter}
   onHide={onHide}
  >
   <div className="confirmation-content">
    <i
     className="pi pi-exclamation-triangle mr-3"
     style={{ fontSize: "2rem" }}
    />
    {data && (
     <span>{!text ? t("Are you sure you want to delete") : t(text)}?</span>
    )}
   </div>
  </Dialog>
 );
};

export default DataTableDeleteDialog;
