export enum GENDER {
 MAN = "MAN",
 WOMAN = "WOMAN",
}

export enum STATUS {
 INACTIVE = "INACTIVE",
 ACTIVE = "ACTIVE",
}

export enum ORDERS_STATUS {
 NEW = "NEW",
 PREPARING = "PREPARING",
 SENT = "SENT",
 CANCELED = "CANCELED",
 RETURN = "RETURN",
 FINISHED = "FINISHED",
}

export enum ROLE {
 ADMIN = "ADMIN",
 MANAGER = "MANAGER",
 OPERATOR = "OPERATOR",
}

export enum MBI_TYPES {
 CMP = "CMP",
 PRD = "PRD",
 URL = "URL",
 VIW = "VIW",
}

export enum PRODUCT_TYPES {
 DIGITAL = "Digital",
 PHYSICAL = "Physical",
 DRAW = "Draw",
}

export enum CONFIG_VARS {
 LIGHT = "light",
 DARK = "dark",
 LIGHT_THEME = "lara-light-indigo",
 DARK_THEME = "lara-dark-indigo",
 SCALE = 14,
}
