import { ORDERS_STATUS } from "@/constants/enums";
import { Button, Dialog } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";

interface DataTableConfirmDialogProps {
 visible: boolean;
 onHide: () => void;
 onConfirm: () => void;
 status: ORDERS_STATUS | null | undefined;
}

const statusOptions = [
 ORDERS_STATUS.NEW,
 ORDERS_STATUS.PREPARING,
 ORDERS_STATUS.SENT,
 ORDERS_STATUS.FINISHED,
];

const OrderConfirmDialog: React.FC<DataTableConfirmDialogProps> = ({
 visible,
 onHide,
 onConfirm,
 status,
}) => {
 const { t } = useTranslation();

 const nextStatus =
  statusOptions[statusOptions.indexOf(status as ORDERS_STATUS) + 1];

 const confirmDialogFooter = (
  <>
   <Button
    label={t("No")}
    icon="pi pi-times"
    className="p-button-text"
    onClick={onHide}
   />
   <Button
    label={t("Yes")}
    icon="pi pi-check"
    className="p-button-text"
    onClick={onConfirm}
   />
  </>
 );

 return (
  <Dialog
   visible={visible}
   style={{ width: "450px" }}
   header="Confirm"
   modal
   footer={confirmDialogFooter}
   onHide={onHide}
  >
   <div className="confirmation-content">
    <i
     className="pi pi-exclamation-triangle mr-3"
     style={{ fontSize: "2rem" }}
    />
    {t("Order status will be set as")}
    <span
     className={`badge ml-2 status-${
      nextStatus ? nextStatus.toLowerCase() : ""
     }`}
    >
     {nextStatus}
    </span>
   </div>
  </Dialog>
 );
};

export default OrderConfirmDialog;
