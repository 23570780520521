import { CampaignDataTable } from "@/components";
import React from "react";

const Campaigns = () => {
 return (
  <div className="datatable-crud">
   <div className="card">
    <CampaignDataTable />
   </div>
  </div>
 );
};

export default Campaigns;
