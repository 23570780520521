import { ICustomer } from "@/models";
import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog } from "primereact";
import React, { useState } from "react";
import { customerRadioButtonFields } from "@/constants";
import {
 InputField,
 CalendarField,
 DropdownField,
 RadioButtonField,
 RadioButtonBoxField,
 ErrorField,
 DataTableDialogFooter,
 InputMaskField,
} from "@/components";
import { isFormFieldValid, capitalizeFirstLetter } from "@/utils";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface CustomerDialogProps {
 visible: boolean;
 customer: ICustomer;
 isLoading: boolean;
 onHide: () => void;
 saveCustomer: (customer: ICustomer) => void;
}

const CustomerDialog: React.FC<CustomerDialogProps> = ({
 visible,
 customer,
 isLoading,
 onHide,
 saveCustomer,
}) => {
 const [gender, setGender] = useState(null);
 const { t } = useTranslation();

 const hideDialog = () => {
  setGender(null);
  onHide();
  formik.resetForm();
 };

 const cities = ["İstanbul", "Sakarya"];

 const formik = useFormik({
  initialValues: customer,
  enableReinitialize: true,
  onSubmit: (data) => {
   saveCustomer(data);
  },
 });

 const setCustomerGender = (value: any) => {
  setGender(value);
  formik.values.gender = value;
 };

 return (
  <Dialog
   visible={visible}
   style={{ width: "450px" }}
   header={t("User Details")}
   modal
   className="p-fluid"
   footer={
    <DataTableDialogFooter
     loading={isLoading}
     hideDialog={hideDialog}
     handleSubmit={formik.handleSubmit}
    />
   }
   onHide={hideDialog}
  >
   <form onSubmit={formik.handleSubmit}>
    <InputField
     id="name"
     value={formik.values.name ?? ""}
     type="text"
     required
     autoFocus={true}
     onChange={formik.handleChange}
     labelName="name"
     labelText={capitalizeFirstLetter("name")}
     getFormErrorMessage={() => <ErrorField formik={formik} name="name" />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "name"),
     })}
    />
    <InputField
     id="surname"
     value={formik.values.surname ?? ""}
     type="text"
     required
     onChange={formik.handleChange}
     labelName="surname"
     labelText={capitalizeFirstLetter("surname")}
     getFormErrorMessage={() => <ErrorField formik={formik} name="surname" />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "surname"),
     })}
    />
    <InputField
     id="email"
     value={formik.values.email ?? ""}
     type="email"
     disabled
     required
     labelName="email"
     labelText={capitalizeFirstLetter("email")}
     getFormErrorMessage={() => <ErrorField formik={formik} name="email" />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "email"),
     })}
    />
    <InputMaskField
     id="gsmno"
     value={formik.values.gsmno ?? ""}
     type="gsmno"
     mask="(999) 999 99 99"
     required
     onChange={formik.handleChange}
     labelName="gsmno"
     labelText={capitalizeFirstLetter("gsmno")}
     getFormErrorMessage={() => <ErrorField formik={formik} name="gsmno" />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "gsmno"),
     })}
    />
    <DropdownField
     id="city"
     name="city"
     value={formik.values.city}
     onChange={formik.handleChange}
     options={cities}
     labelName={"city"}
     labelText={capitalizeFirstLetter("city")}
     getFormErrorMessage={() => <ErrorField formik={formik} name={"city"} />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "city"),
     })}
    />
    <CalendarField
     id="birthDate"
     name="birthDate"
     value={moment(formik.values.birthDate).toDate()}
     onChange={formik.handleChange}
     showIcon
     dateFormat="dd/mm/yy"
     mask="99/99/9999"
     labelName={"birthDate"}
     labelText={capitalizeFirstLetter("birthdate")}
    />
    <RadioButtonBoxField
     style={{ margin: "30px 0" }}
     className={classNames("field", {
      "p-invalid": isFormFieldValid(formik, "gender"),
     })}
    >
     {customerRadioButtonFields.map((item, index) => (
      <RadioButtonField
       inputId={`gender${index}`}
       key={index}
       name="gender"
       value={item}
       onChange={(e: any) => setCustomerGender(e.target.value)}
       checked={gender === item || formik.values.gender === item}
       labelName={`gender${index}`}
       labelText={item}
      />
     ))}
     {<ErrorField formik={formik} name={"gender"} />}
    </RadioButtonBoxField>
   </form>
  </Dialog>
 );
};

export default CustomerDialog;
