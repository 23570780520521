import { IProduct } from "@/models";
import { addProduct, addProductImages } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useAddProductImagesData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const { mutate, isLoading: addProductImagesIsLoading } = useMutation(
  async (productImages: FormData) => await addProductImages(productImages),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("products");
    toastSuccess(toast, "Product Images Added");
   },
   onError: () => {
    toastError(toast, "Product Images not Added");
   },
  },
 );
 return { mutate, addProductImagesIsLoading };
};
