export const TRANSLATIONS_TR = {
 Home: "Anasayfa",
 Dashboard: "Ana ekran",
 Customers: "Müşteriler",
 "Customers Management": "Müşteri Yönetimi",
 "Products Management": "Ürün Yönetimi",
 Products: "Ürünler",
 Suppliers: "Tedarikçiler",
 Categories: "Kategoriler",
 System: "Sistem",
 Users: "Kullanıcılar",

 // Datatable and dialog
 "Manage Customers": "Müşterileri Yönet",
 "Manage Products": "Ürünleri Yönet",
 "Manage Users": "Kullanıcıları Yönet",
 "Manage Suppliers": "Tedarikçileri Yönet",
 "Manage Categories": "Kategorileri Yönet",
 "Customer Details": "Müşteri Detayları",
 "Product Details": "Ürün Detayları",
 "User Details": "Kullanıcı Detayları",
 "Supplier Details": "Tedarikçi Detayları",
 "Category Details": "Kategori Detayları",
 "Are you sure you want to delete": "Silmek istediğine emin misin",
 "Showing {first} to {last} of {totalRecords} customers":
  "{totalRecords} müşteriden {first} ile {last} tanesi gösteriliyor",
 "Showing {first} to {last} of {totalRecords} products":
  "{totalRecords} üründen {first} ile {last} tanesi gösteriliyor",
 "Showing {first} to {last} of {totalRecords} users":
  "{totalRecords} kullanıcıdan {first} ile {last} tanesi gösteriliyor",
 "Showing {first} to {last} of {totalRecords} suppliers":
  "{totalRecords} tedarikçiden {first} ile {last} tanesi gösteriliyor",
 "Showing {first} to {last} of {totalRecords} categories":
  "{totalRecords} kategoriden {first} ile {last} tanesi gösteriliyor",
 Name: "İsim",
 Surname: "Soyisim",
 Email: "Email",
 Password: "Şifre",
 "Gsm No": "Gsm Numarası",
 Gsmno: "Gsm Numarası",
 Address: "Adres",
 City: "Şehir",
 Birthdate: "Doğum Günü",
 MAN: "ERKEK",
 WOMAN: "KADIN",
 Status: "Durum",
 Type: "Tür",
 New: "Yeni",
 Delete: "Sil",
 "Search...": "Ara...",
 Yes: "Evet",
 No: "Hayır",
 Cancel: "İptal",
 Save: "Kaydet",
 Language: "Dil",

 ENGLISH: "İNGİLİZCE",
 TURKISH: "TÜRKÇE",
 URDU: "URDUCA",
};
