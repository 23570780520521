import { axiosInstance } from "@/api/axios.instance";
import { IMbi } from "@/models";

export const getMbis = async (): Promise<IMbi[]> =>
 await axiosInstance.get(`/mbis`).then((res) => res.data);

export const addMbi = async (mainBoard: Omit<IMbi, "imageUrl">) =>
 await axiosInstance.post(`/mbis`, mainBoard);

export const updateMbi = async (mainBoard: Omit<IMbi, "imageUrl">) =>
 await axiosInstance.put(`/mbis`, mainBoard);

export const deleteMbi = async (mbisId: bigint) =>
 await axiosInstance.delete(`/mbis/${mbisId}`);

export const orderMbis = async (mbis: Array<Omit<IMbi, "imageUrl">>) =>
 await axiosInstance.post(`/mbis/order`, mbis);
