import { Button, classNames, Dialog } from "primereact";
import React from "react";
import { ErrorField, InputField } from "@/components";
import { capitalizeFirstLetter, isFormFieldValid } from "@/utils";
import { useFormik } from "formik";
import { IOrder } from "@/models";
import { orderSchema } from "@/validations";

interface Props {
 visible: boolean;
 order: IOrder | undefined;
 onHide: () => void;
 onSave: (data: { orderId: bigint; trackingNumber: string }) => void;
}

const OrderTrackingNumberDialog: React.FC<Props> = ({
 visible,
 onHide,
 order,
 onSave,
}) => {
 const formik = useFormik({
  initialValues: {
   orderId: order ? order.id : BigInt(0),
   trackingNumber: order ? order.trackingNumber : "",
  },
  enableReinitialize: true,
  validationSchema: orderSchema,
  onSubmit: (data) => {
   formik.setFieldValue("orderId", order?.id);
   order && onSave(data);
   formik.resetForm();
   onHide();
  },
 });

 return (
  <Dialog
   visible={visible}
   style={{ width: "450px" }}
   header="Tracking Number"
   modal
   className="p-fluid"
   onHide={onHide}
   footer={
    <div>
     <Button
      label="Cancel"
      icon="pi pi-times"
      className="p-button-text"
      onClick={onHide}
     />
     <Button
      label="Save"
      icon="pi pi-check"
      className="p-button-text"
      onClick={() => formik.handleSubmit()}
     />
    </div>
   }
  >
   <form onSubmit={formik.handleSubmit}>
    <InputField
     id="trackingNumber"
     value={formik.values.trackingNumber ?? ""}
     type="text"
     required
     autoFocus={true}
     onChange={formik.handleChange}
     labelName="trackingNumber"
     labelText={capitalizeFirstLetter("tracking Number")}
     getFormErrorMessage={() => (
      <ErrorField formik={formik} name="trackingNumber" />
     )}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "trackingNumber"),
     })}
    />
   </form>
  </Dialog>
 );
};

export default OrderTrackingNumberDialog;
