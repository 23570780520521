import { deleteCampaign } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useDeleteCampaignData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const { mutate, isLoading: deleteCampaignIsLoading } = useMutation(
  async (campaignId: bigint) => await deleteCampaign(campaignId),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("campaigns");
    toastSuccess(toast, "Campaign Deleted");
   },
   onError: () => {
    toastError(toast, "Campaign not Deleted");
   },
  },
 );
 return {
  mutate,
  deleteCampaignIsLoading,
 };
};
