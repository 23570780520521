import { ICustomer } from "@/models";
import { updateCustomer } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateCustomerData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const {
  mutate,
  isLoading: updateCustomerIsLoading,
  isSuccess: updateCustomerIsSuccess,
 } = useMutation(
  async (customer: ICustomer) => await updateCustomer(customer),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("customers");
    toastSuccess(toast, "Customer Updated");
   },
   onError: () => {
    toastError(toast, "Customer not Updated");
   },
  },
 );
 return {
  mutate,
  updateCustomerIsLoading,
  updateCustomerIsSuccess,
 };
};
