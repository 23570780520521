import { IProduct } from "@/models";
import { updateProduct } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateProductData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const {
  mutate,
  isLoading: updateProductIsLoading,
  isSuccess: updateProductIsSuccess,
 } = useMutation(
  async (product: Omit<IProduct, "imageUrl">) => await updateProduct(product),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("products");
    toastSuccess(toast, "Product Updated");
   },
   onError: () => {
    toastError(toast, "Product not Updated");
   },
  },
 );
 return { mutate, updateProductIsLoading, updateProductIsSuccess };
};
