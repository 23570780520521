import React from "react";
import { CategoryDataTable } from "@/components";
const Categories = () => {
 return (
  <div className="datatable-crud">
   <div className="card">
    <CategoryDataTable />
   </div>
  </div>
 );
};

export default Categories;
