import { useGetOrderListData } from "@/hooks";
import moment from "moment";
import { Column, DataTable } from "primereact";
import { useTranslation } from "react-i18next";

const NewOrderAnalyticCard = () => {
 const { orderList, getOrderListIsLoading } = useGetOrderListData();
 const { t } = useTranslation();

 return (
  <div className="col-12 m-0">
   <div className="card mb-0">
    <h5>New Orders</h5>
    <DataTable
     value={orderList}
     loading={getOrderListIsLoading}
     responsiveLayout="scroll"
     paginator
     rows={10}
     rowsPerPageOptions={[10, 20, 50]}
     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
     currentPageReportTemplate={t(
      `Showing {first} to {last} of {totalRecords} Orders`,
     )}
    >
     <Column
      field="productName"
      header="Product Name"
      sortable
      style={{ width: "35%" }}
     />
     <Column
      field="date"
      header="Date"
      sortable
      style={{ width: "35%" }}
      body={(rowData) => <>{moment(rowData.date).format("DD/MM/YYYY")}</>}
     />
    </DataTable>
   </div>
  </div>
 );
};

export default NewOrderAnalyticCard;
