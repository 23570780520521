import { IComponentProps } from "@/models";
import React from "react";

interface MainLayoutProps extends IComponentProps {}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
 return <div className="layout-main">{children}</div>;
};

export default MainLayout;
