import { Dialog } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";
import { OrderDetailDataTable } from "@/components";
import { IOrder } from "@/models";

interface OrderDialogProps {
 visible: boolean;
 isLoading: boolean;
 onHide: () => void;
 order: IOrder | undefined;
}

const OrderDetailDialog: React.FC<OrderDialogProps> = ({
 visible,
 isLoading,
 onHide,
 order,
}) => {
 const { t } = useTranslation();

 const hideDialog = () => {
  onHide();
 };

 return (
  <Dialog
   visible={visible}
   style={{ width: "50%" }}
   header={t("Order Details")}
   modal
   className="p-fluid"
   onHide={hideDialog}
  >
   <div className="datatable-crud">
    <div className="card">
     <OrderDetailDataTable order={order} />
    </div>
   </div>
  </Dialog>
 );
};

export default OrderDetailDialog;
