import { CONFIG_VARS } from "@/constants";
import { useTheme } from "@/hooks";
import { ICustomer } from "@/models";
import moment from "moment";
import React from "react";

interface CustomerDialogProps {
 customer: ICustomer | undefined;
}

const CustomerDetailDataTable: React.FC<CustomerDialogProps> = ({
 customer,
}) => {
 const { layoutColorMode } = useTheme();

 return (
  <>
   {customer && (
    <table
     style={{
      border: `1px solid ${
       layoutColorMode === CONFIG_VARS.LIGHT
        ? "rgba(0, 0, 0, .05)"
        : "rgba(255, 255, 255, 0.6)"
      }`,
      borderCollapse: "collapse",
      textAlign: "left",
      width: "100%",
     }}
    >
     <tbody>
      <tr>
       <th
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        Customer Name:
       </th>
       <td
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        {customer.name?.toString()}
       </td>
      </tr>
      <tr>
       <th
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        Customer Surname:
       </th>
       <td
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        {customer.surname?.toString()}
       </td>
      </tr>
      <tr>
       <th
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        Email:
       </th>
       <td
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        {customer.email?.toString()}
       </td>
      </tr>
      <tr>
       <th
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        Gsm No:
       </th>
       <td
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        {customer.gsmno?.toString()}
       </td>
      </tr>
      <tr>
       <th
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        Birth Date:
       </th>
       <td
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        {customer.birthDate
         ? moment(customer.birthDate).format("DD/MM/YYYY").toString()
         : ""}
       </td>
      </tr>
      <tr>
       <th
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        Gender:
       </th>
       <td
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        {customer.gender?.toString()}
       </td>
      </tr>
      <tr>
       <th
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        City:
       </th>
       <td
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        {customer.city?.toString()}
       </td>
      </tr>
      <tr>
       <th
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        CKP:
       </th>
       <td
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        {customer.moneyAmount?.toString()}
       </td>
      </tr>
      <tr>
       <th
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        Last Login Date:
       </th>
       <td
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        {customer.lastlogindate
         ? moment(customer.lastlogindate).format("DD/MM/YYYY").toString()
         : null}
       </td>
      </tr>
      <tr>
       <th
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        Status:
       </th>
       <td
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        <span
         className={`badge status-${
          customer.status ? customer.status.toLowerCase() : ""
         }`}
        >
         {customer.status?.toString()}
        </span>
       </td>
      </tr>
     </tbody>
    </table>
   )}
  </>
 );
};

export default CustomerDetailDataTable;
