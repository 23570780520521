import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog, TabMenu } from "primereact";
import React, { useEffect, useState } from "react";
import { InputField, ErrorField, DataTableDialogFooter } from "@/components";
import { isFormFieldValid, capitalizeFirstLetter } from "@/utils";
import { useTranslation } from "react-i18next";
import { ICategory } from "@/models";

interface CategoryDialogProps {
 visible: boolean;
 category: ICategory;
 isLoading: boolean;
 onHide: () => void;
 addCategory: (category: ICategory) => void;
}

const items = [
 { label: "Main", icon: "pi pi-fw pi-home" },
 { label: "SecondLanguage", icon: "pi pi-fw pi-language" },
];

const CategoryDialog: React.FC<CategoryDialogProps> = ({
 visible,
 category,
 isLoading,
 onHide,
 addCategory,
}) => {
 const { t } = useTranslation();
 const [activeIndex, setActiveIndex] = useState(0);

 useEffect(() => {
  formik.resetForm();
 }, [visible]);

 const hideDialog = () => {
  onHide();
  formik.resetForm();
 };

 const formik = useFormik({
  initialValues: category,
  enableReinitialize: true,
  onSubmit: (data) => {
   addCategory(data);
  },
 });

 return (
  <Dialog
   visible={visible}
   style={{ width: "450px" }}
   header={t("Category Details")}
   modal
   className="p-fluid"
   footer={
    <DataTableDialogFooter
     loading={isLoading}
     hideDialog={hideDialog}
     handleSubmit={formik.handleSubmit}
    />
   }
   onHide={hideDialog}
  >
   <TabMenu
    model={items}
    className="mb-5"
    activeIndex={activeIndex}
    onTabChange={(e) => setActiveIndex(e.index)}
   />
   <form onSubmit={formik.handleSubmit}>
    {activeIndex === 0 ? (
     <>
      <InputField
       id="title"
       value={formik.values.title ?? ""}
       type="text"
       required
       autoFocus={true}
       onChange={formik.handleChange}
       labelName="title"
       labelText={capitalizeFirstLetter("title")}
       getFormErrorMessage={() => <ErrorField formik={formik} name={"title"} />}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "title"),
       })}
      />
      <InputField
       id="description"
       value={formik.values.description ?? ""}
       type="text"
       required
       onChange={formik.handleChange}
       labelName="description"
       labelText={capitalizeFirstLetter("description")}
       getFormErrorMessage={() => (
        <ErrorField formik={formik} name={"description"} />
       )}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "description"),
       })}
      />
     </>
    ) : (
     <>
      <InputField
       id="title2lng"
       value={formik.values.title2lng ?? ""}
       type="text"
       required
       autoFocus={true}
       onChange={formik.handleChange}
       labelName="title2lng"
       labelText={capitalizeFirstLetter("title")}
       getFormErrorMessage={() => (
        <ErrorField formik={formik} name={"title2lng"} />
       )}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "title2lng"),
       })}
      />
      <InputField
       id="description2lng"
       value={formik.values.description2lng ?? ""}
       type="text"
       required
       onChange={formik.handleChange}
       labelName="description2lng"
       labelText={capitalizeFirstLetter("description")}
       getFormErrorMessage={() => (
        <ErrorField formik={formik} name={"description2lng"} />
       )}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "description2lng"),
       })}
      />
     </>
    )}
   </form>
  </Dialog>
 );
};

export default CategoryDialog;
