import React from "react";

const NotFound = () => {
 return (
  <div className="card">
   <h4>Empty Page</h4>
   <p>Page Not Found</p>
  </div>
 );
};

export default NotFound;
