import { axiosInstance } from "@/api/axios.instance";
import { IOrder } from "@/models";

export const getOrders = async (): Promise<IOrder[]> =>
 await axiosInstance
  .get(`/orders/listwithoutstatusfinishcancelreturn`)
  .then((res) => res.data);

export const getInActiveOrders = async (): Promise<IOrder[]> =>
 await axiosInstance
  .get(`/orders/listcontainingstatusfinishcancelreturn`)
  .then((res) => res.data);

export const setOrderPreparing = async (orderId: bigint) =>
 await axiosInstance.post(`/orders/setpreparing?orderId=${orderId}`);

export const setOrderSenting = async ({
 orderId,
 trackingNumber,
}: {
 orderId: bigint;
 trackingNumber: string;
}) =>
 await axiosInstance.post(
  `/orders/setsending`,
  {},
  {
   params: {
    orderId,
    trackingNumber,
   },
  },
 );

export const setOrderFinish = async (orderId: bigint) =>
 await axiosInstance.post(`/orders/setfinish?orderId=${orderId}`);

export const setOrderCancel = async (orderId: bigint) =>
 await axiosInstance.post(`/orders/setcanceled?orderId=${orderId}`);

export const setOrderReturn = async (orderId: bigint) =>
 await axiosInstance.post(`/orders/setreturn?orderId=${orderId}`);
