import { getProducts } from "@/services";
import { useQuery } from "react-query";

export const useGetProductsData = (enabled?: boolean) => {
 const {
  data: products,
  isLoading: getProductsIsLoading,
  refetch: refetchGetProducts,
 } = useQuery("products", getProducts, {
  enabled,
 });
 return { products, getProductsIsLoading, refetchGetProducts };
};
