import { FilterMatchMode, FilterOperator } from "primereact";

export const customerFilters = {
 global: { value: null, matchMode: FilterMatchMode.CONTAINS },
 name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
 surname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
 gsmno: { value: null, matchMode: FilterMatchMode.CONTAINS },
 city: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
 status: { value: null, matchMode: FilterMatchMode.EQUALS },
 type: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
};

export const productFilters = {
 global: { value: null, matchMode: FilterMatchMode.CONTAINS },
 name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
 type: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
 sellingPoint: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
 stockQuantity: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
};

export const campaignFilters = {
 global: { value: null, matchMode: FilterMatchMode.CONTAINS },
 name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
 description: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
 limitValue: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
 bonus: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
 status: { value: null, matchMode: FilterMatchMode.EQUALS },
};

export const mbiFilters = {
 global: { value: null, matchMode: FilterMatchMode.CONTAINS },
 name: {
  operator: FilterOperator.AND,
  constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
 },
 type: {
  operator: FilterOperator.AND,
  constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
 },
};

export const orderFilters = {
 global: { value: null, matchMode: FilterMatchMode.CONTAINS },
 customerName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
 productName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
 status: { value: null, matchMode: FilterMatchMode.EQUALS },
};

export const userFilters = {
 global: { value: null, matchMode: FilterMatchMode.CONTAINS },
 name: {
  operator: FilterOperator.AND,
  constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
 },
 email: {
  operator: FilterOperator.AND,
  constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
 },
 type: {
  operator: FilterOperator.AND,
  constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
 },
};

export const categoryFilters = {
 global: { value: null, matchMode: FilterMatchMode.CONTAINS },
 title: {
  operator: FilterOperator.AND,
  constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
 },
 description: {
  operator: FilterOperator.AND,
  constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
 },
};
