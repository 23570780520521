import { ICustomer } from "@/models";
import { Column, DataTable } from "primereact";
import { DataTableStatusTemplate } from "@/components";
import { useGetTopTenCustomersData } from "@/hooks";

const TopTenCustomersAnalyticCard = () => {
 const { topTenCustomers, getTopTenCustomersIsLoading } =
  useGetTopTenCustomersData();

 return (
  <div className="col-12 m-0">
   <div className="card mb-0">
    <h5>Top Ten Users</h5>
    <DataTable
     value={topTenCustomers}
     loading={getTopTenCustomersIsLoading}
     responsiveLayout="scroll"
    >
     <Column field="name" header="Name" sortable style={{ width: "35%" }} />
     <Column
      field="moneyAmount"
      header="Money Amount"
      sortable
      style={{ width: "35%" }}
     />
     <Column
      field="status"
      header="Status"
      sortable
      style={{ width: "15%" }}
      body={(rowData: ICustomer) => (
       <DataTableStatusTemplate status={rowData.status} />
      )}
     />
    </DataTable>
   </div>
  </div>
 );
};

export default TopTenCustomersAnalyticCard;
