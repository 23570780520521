import { axiosInstance } from "@/api/axios.instance";
import { IProduct } from "@/models";
import { QueryFunctionContext } from "react-query";

export const getProducts = async (): Promise<IProduct[]> =>
 await axiosInstance.get(`/product`).then((res) => res.data);

export const getProduct = async ({
 queryKey,
}: QueryFunctionContext<[string, bigint | null]>): Promise<IProduct> => {
 const [, productId] = queryKey;
 return await axiosInstance
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  .get(`/product/${productId}`)
  .then((res) => res.data);
};

export const addProduct = async (product: Omit<IProduct, "imageUrl">) =>
 await axiosInstance.post(`/product`, product);

export const addProductImages = async (productImages: FormData) =>
 await axiosInstance.post(`/product/images`, productImages, {
  headers: {
   "Content-Type": "multipart/form-data",
  },
 });

export const deleteProductImage = async (
 imageId: bigint | number,
): Promise<any> => await axiosInstance.delete(`/product/image/${imageId}`);

export const updateProduct = async (product: Omit<IProduct, "imageUrl">) =>
 await axiosInstance.put(`/product`, product);

export const deleteProduct = async (productId: bigint) =>
 await axiosInstance.delete(`/product/${productId}`);
