import { axiosInstance } from "@/api/axios.instance";
import { ICustomer, ICustomerCount } from "@/models";

export const getCustomers = async (): Promise<ICustomer[]> =>
 await axiosInstance.get(`/customer`).then((res) => res.data);

export const updateCustomer = async (customer: ICustomer) =>
 await axiosInstance.put(`/customer`, customer);

export const deleteCustomer = async (customerId: number) =>
 await axiosInstance.delete(`/customer/${customerId}`);
