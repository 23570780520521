import { useState } from "react";
import MenuContext from "@contexts/MenuContext";

interface MenuProviderProps {
 children: React.ReactNode;
}

export const MenuProvider: React.FC<MenuProviderProps> = ({ children }) => {
 const [menuIsActive, setMenuIsActive] = useState(true);
 return (
  <MenuContext.Provider value={{ menuIsActive, setMenuIsActive }}>
   {children}
  </MenuContext.Provider>
 );
};
