import { setOrderSenting } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useSetOrderSendingData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const { mutate: sentingMutate } = useMutation(
  async ({
   orderId,
   trackingNumber,
  }: {
   orderId: bigint;
   trackingNumber: string;
  }) => await setOrderSenting({ orderId, trackingNumber }),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("orders");
    toastSuccess(toast, "Order set sending.");
   },
   onError: () => {
    toastError(toast, "Order not set sending.");
   },
  },
 );
 return {
  sentingMutate,
 };
};
