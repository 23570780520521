import { useGetQuantityProductsData } from "@/hooks";
import { Column, DataTable } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";

const ProductQuantityAnalyticCard = () => {
 const { quantityProducts, getQuantityProductsIsLoading } =
  useGetQuantityProductsData();
 const { t } = useTranslation();
 return (
  <div className="col-12 m-0">
   <div className="card mb-0">
    <h5>Products</h5>
    <DataTable
     value={quantityProducts}
     loading={getQuantityProductsIsLoading}
     responsiveLayout="scroll"
     paginator
     rows={10}
     rowsPerPageOptions={[10, 20, 50]}
     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
     currentPageReportTemplate={t(
      `Showing {first} to {last} of {totalRecords} Orders`,
     )}
    >
     <Column field="name" header="Product Name" style={{ width: "35%" }} />
     <Column
      field="stockQuantity"
      header="Stock Quantity"
      style={{ width: "35%" }}
     />
    </DataTable>
   </div>
  </div>
 );
};

export default ProductQuantityAnalyticCard;
