import { getCountWithMoneyBetween } from "@/services";
import { useQuery } from "react-query";

export const useGetCountWithMoneyBetweenData = ({
 moneyAmountFirst,
 moneyAmountSecond,
}: {
 moneyAmountFirst: number;
 moneyAmountSecond: number;
}) => {
 const {
  data: countWithMoneyBetween,
  isLoading: getCountWithMoneyBetweenLoading,
 } = useQuery(
  ["countwithmoneybetween", { moneyAmountFirst, moneyAmountSecond }],
  getCountWithMoneyBetween,
 );
 return { countWithMoneyBetween, getCountWithMoneyBetweenLoading };
};
