import { useState } from "react";
import { IComponentProps } from "@/models";
import RoleContext from "@/contexts/RoleContext";
import { ROLE } from "@/constants";

interface RoleProviderProps extends IComponentProps {}

export const RoleProvider: React.FC<RoleProviderProps> = ({ children }) => {
 const [role, setRole] = useState<ROLE | undefined>(ROLE.OPERATOR);
 return (
  <RoleContext.Provider value={{ role, setRole }}>
   {children}
  </RoleContext.Provider>
 );
};
