import { deleteMbi } from "@/services";
import { useMutation, useQueryClient } from "react-query";
import { Toast } from "primereact";
import { toastError, toastSuccess } from "@/utils";

export const useDeleteMbiData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const { mutate, isLoading: deleteMbiIsLoading } = useMutation(
  async (mbiId: bigint) => await deleteMbi(mbiId),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("mbis");
    toastSuccess(toast, "Main Board Item Deleted");
   },
   onError: () => {
    toastError(toast, "Main Board Item not Deleted");
   },
  },
 );
 return {
  mutate,
  deleteMbiIsLoading,
 };
};
