import {
 AdsWatchCountPerDayChart,
 CustomerCountOneMonthChart,
 DashboardCard,
 DashboardHeader,
 NewOrderAnalyticCard,
 ProductQuantityAnalyticCard,
 TopTenCustomersAnalyticCard,
} from "@/components";
import {
 useGetOrderListData,
 useGetCustomerCountData,
 useGetOrdersData,
 useRole,
 useGetAdsCountWithDateData,
 useGetCountWithMoneyBetweenData,
} from "@/hooks";
import { useTranslation } from "react-i18next";
import { Col, Grid } from "@/layouts";
import moment from "moment";
import { useState } from "react";
import { InputNumber } from "primereact";

const MainDashboard = () => {
 const [moneyAmount, setMoneyAmount] = useState({
  moneyAmountFirst: 100,
  moneyAmountSecond: 200,
 });
 const { moneyAmountFirst, moneyAmountSecond } = moneyAmount;

 const { orderList } = useGetOrderListData();
 const { orders, getOrdersIsLoading } = useGetOrdersData();
 const { customerCount, getCustomerCountIsLoading } = useGetCustomerCountData();
 const { adsCountWithDate, getAdsCountWithDateIsLoading } =
  useGetAdsCountWithDateData();
 const { countWithMoneyBetween, getCountWithMoneyBetweenLoading } =
  useGetCountWithMoneyBetweenData({
   moneyAmountFirst,
   moneyAmountSecond,
  });
 const { t } = useTranslation();
 const { role } = useRole();

 return (
  <>
   <Grid className="m-0">
    <Col className="col-12 flex align-items-center m-0 py-0">
     <DashboardHeader role={role} />
    </Col>

    <Col className="col-12 xl:col-6 px-0">
     <Grid className="m-0">
      <DashboardCard
       title="Orders"
       count={`${orderList?.length ?? 0} New`}
       icon="pi-shopping-cart"
       iconTextColor="text-blue-500"
       iconBgColor="bg-blue-100"
       footerTitle={`${orders?.length ?? 0} Total Orders`}
       className="col-12 lg:col-6"
       isLoading={getOrdersIsLoading}
      />
      <DashboardCard
       title="Users"
       count={`${customerCount?.activeCustomerCount ?? 0} Active`}
       icon="pi-users"
       iconTextColor="text-orange-500"
       iconBgColor="bg-orange-100"
       className="col-12 lg:col-6"
       footerTitle={`${customerCount?.totalCustomerCount ?? 0} Total Users`}
       isLoading={getCustomerCountIsLoading}
      />
      <DashboardCard
       title="Advertisements"
       // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
       count={`${adsCountWithDate?.numberOfAdsViewPerDay ?? 0} Watched`}
       icon="pi-inbox"
       iconTextColor="text-cyan-500"
       iconBgColor="bg-cyan-100"
       className="col-12 lg:col-6"
       footerTitle={`${
        moment(adsCountWithDate?.date).format("DD/MM/YYYY") ?? ""
       }`}
       isLoading={getAdsCountWithDateIsLoading}
      />
      <DashboardCard
       title="Count With Money Between"
       // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
       count={`${countWithMoneyBetween?.count ?? 0} Users`}
       icon="pi-dollar"
       iconTextColor="text-purple-500"
       iconBgColor="bg-purple-100"
       className="col-12 lg:col-6"
       footerTitle={`Between ${moneyAmountFirst} and ${moneyAmountSecond}`}
       isLoading={getCountWithMoneyBetweenLoading}
      />
     </Grid>
     <Col className="col-12 m-0">
      <CustomerCountOneMonthChart />
     </Col>
     <ProductQuantityAnalyticCard />
    </Col>

    <Col className="col-12 xl:col-6 px-0">
     <Col className="col-12 m-0">
      <AdsWatchCountPerDayChart />
     </Col>
     <NewOrderAnalyticCard />
     <TopTenCustomersAnalyticCard />
    </Col>
   </Grid>
  </>
 );
};

export default MainDashboard;
