import {
 ErrorField,
 InputField,
 DataTableDialogFooter,
 DropdownField,
} from "@/components";
import { MBI_TYPES } from "@/constants";
import { useGetCampaignsData, useGetProductsData } from "@/hooks";
import { IMbi } from "@/models";
import { capitalizeFirstLetter, isFormFieldValid } from "@/utils";
import { mbiSchema } from "@/validations";
import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";

interface MbiDialogProps {
 visible: boolean;
 mbi: Omit<IMbi, "imageUrl">;
 isLoading: boolean;
 onHide: () => void;
 addMbi: (mbi: Omit<IMbi, "imageUrl">) => void;
}

const MbiDialog: React.FC<MbiDialogProps> = ({
 visible,
 mbi,
 isLoading,
 onHide,
 addMbi,
}) => {
 const { t } = useTranslation();

 const { refetchGetProducts, products } = useGetProductsData(false);
 const { refetchGetCampaigns, campaigns } = useGetCampaignsData(false);

 const mbiTypes = [MBI_TYPES.CMP, MBI_TYPES.PRD, MBI_TYPES.URL, MBI_TYPES.VIW];

 const typeOptions = mbiTypes.map((item) => ({
  label: item,
  value: item,
 }));

 const productOptions = products?.map((product) => ({
  label: product.name,
  value: product,
 }));

 const campaignOptions = campaigns?.map((campaign) => ({
  label: campaign.name,
  value: campaign,
 }));

 const formik = useFormik({
  initialValues: mbi,
  enableReinitialize: true,
  validationSchema: mbiSchema,
  onSubmit: (data) => {
   addMbi(data);
  },
 });

 const hideDialog = () => {
  onHide();
  formik.resetForm();
 };

 const callRefetch = () => {
  if (formik.values.type === MBI_TYPES.PRD) {
   refetchGetProducts();
  } else if (formik.values.type === MBI_TYPES.CMP) {
   refetchGetCampaigns();
  }
 };

 const clearValues = () => {
  formik.values.type === MBI_TYPES.URL
   ? (formik.values.campaign = formik.values.product = "")
   : formik.values.type === MBI_TYPES.CMP
   ? (formik.values.url = formik.values.product = "")
   : formik.values.type === MBI_TYPES.PRD
   ? (formik.values.url = formik.values.campaign = "")
   : (formik.values.campaign = formik.values.product = formik.values.url = "");
 };

 return (
  <Dialog
   visible={visible}
   style={{ width: "450px" }}
   header={t("Main Board Item Details")}
   modal
   className="p-fluid"
   footer={
    <DataTableDialogFooter
     loading={isLoading}
     hideDialog={hideDialog}
     handleSubmit={formik.handleSubmit}
    />
   }
   onHide={hideDialog}
  >
   <form onSubmit={formik.handleSubmit}>
    <DropdownField
     id="type"
     name="type"
     value={formik.values.type ?? ""}
     required
     autoFocus={true}
     onChange={formik.handleChange}
     onClickCapture={clearValues}
     options={typeOptions}
     filter={true}
     labelName="type"
     labelText={capitalizeFirstLetter("type")}
     getFormErrorMessage={() => <ErrorField formik={formik} name="type" />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "type"),
     })}
    />
    <InputField
     id="title"
     value={formik.values.title ?? ""}
     type="text"
     required
     onChange={formik.handleChange}
     labelName="title"
     labelText={capitalizeFirstLetter("title")}
     getFormErrorMessage={() => <ErrorField formik={formik} name="title" />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "title"),
     })}
    />
    <InputField
     id="description"
     value={formik.values.description ?? ""}
     type="text"
     required
     onChange={formik.handleChange}
     labelName="description"
     labelText={capitalizeFirstLetter("description")}
     getFormErrorMessage={() => (
      <ErrorField formik={formik} name="description" />
     )}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "description"),
     })}
    />
    <InputField
     id="url"
     value={formik.values.type === MBI_TYPES.URL ? formik.values.url : ""}
     type="text"
     required
     disabled={formik.values.type !== MBI_TYPES.URL}
     onChange={formik.handleChange}
     labelName="url"
     labelText={capitalizeFirstLetter("url")}
     getFormErrorMessage={() => <ErrorField formik={formik} name="url" />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "url"),
     })}
    />
    <DropdownField
     id="product"
     name="product"
     value={formik.values.type === MBI_TYPES.PRD ? formik.values.product : ""}
     required
     disabled={formik.values.type !== MBI_TYPES.PRD}
     onChange={formik.handleChange}
     onShow={callRefetch}
     options={productOptions ?? []}
     filter={true}
     labelName="product"
     labelText={capitalizeFirstLetter("product")}
     getFormErrorMessage={() => <ErrorField formik={formik} name="product" />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "product"),
     })}
    />
    <DropdownField
     id="campaign"
     name="campaign"
     value={formik.values.type === MBI_TYPES.CMP ? formik.values.campaign : ""}
     required
     disabled={formik.values.type !== MBI_TYPES.CMP}
     onChange={formik.handleChange}
     onShow={callRefetch}
     options={campaignOptions ?? []}
     filter={true}
     labelName="campaign"
     labelText={capitalizeFirstLetter("campaign")}
     getFormErrorMessage={() => <ErrorField formik={formik} name="campaign" />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "campaign"),
     })}
    />
   </form>
  </Dialog>
 );
};

export default MbiDialog;
