import { deleteCategory } from "@/services";
import { useMutation, useQueryClient } from "react-query";
import { Toast } from "primereact";
import { toastError, toastSuccess } from "@/utils";

export const useDeleteCategoryData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const { mutate, isLoading: deleteCategoryIsLoading } = useMutation(
  async (categoryId: bigint) => await deleteCategory(categoryId),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("categories");
    toastSuccess(toast, "Category Deleted");
   },
   onError: () => {
    toastError(toast, "Category not Deleted");
   },
  },
 );
 return {
  mutate,
  deleteCategoryIsLoading,
 };
};
