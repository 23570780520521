import { deleteCampaignImage } from "@/services";
import { useMutation } from "react-query";
import { Toast } from "primereact";
import { toastError, toastSuccess } from "@/utils";

export const useDeleteCampaignImageData = (toast: React.RefObject<Toast>) => {
 const { mutate, isLoading: deleteCampaignImageIsLoading } = useMutation(
  async (campaignId: bigint | number) => await deleteCampaignImage(campaignId),
  {
   onSuccess: () => {
    toastSuccess(toast, "Campaign Image Deleted");
   },
   onError: () => {
    toastError(toast, "Campaign Image not Deleted");
   },
  },
 );
 return {
  mutate,
  deleteCampaignImageIsLoading,
 };
};
