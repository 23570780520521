import { addCampaignImages } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useAddCampaignImagesData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const { mutate, isLoading: addCampaignImagesIsLoading } = useMutation(
  async (campaignImages: FormData) => await addCampaignImages(campaignImages),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("campaigns");
    toastSuccess(toast, "Campaign Images Added");
   },
   onError: () => {
    toastError(toast, "Campaign Images not Added");
   },
  },
 );
 return { mutate, addCampaignImagesIsLoading };
};
