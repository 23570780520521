import React from "react";
import { InputTextarea, InputTextareaProps } from "primereact";
import { useTranslation } from "react-i18next";
import { IFieldProps } from "@/models";

interface InputTextAreaFieldProps extends InputTextareaProps, IFieldProps {}

const InputTextAreaField: React.FC<InputTextAreaFieldProps> = ({
 getFormErrorMessage,
 labelName,
 labelText,
 ...rest
}) => {
 const { t } = useTranslation();

 return (
  <div className="field">
   <label htmlFor={labelName}>{t(labelText)}</label>
   <InputTextarea rows={5} cols={30} {...rest} />
   {getFormErrorMessage != null && getFormErrorMessage()}
  </div>
 );
};

export default InputTextAreaField;
