import { getCustomerCount } from "@/services";
import { useQuery } from "react-query";

export const useGetCustomerCountData = () => {
 const { data: customerCount, isLoading: getCustomerCountIsLoading } = useQuery(
  "customerCount",
  getCustomerCount,
 );
 return { customerCount, getCustomerCountIsLoading };
};
