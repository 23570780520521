import React from "react";
import { useTranslation } from "react-i18next";
import { IOrder } from "@/models";
import moment from "moment";
import { useTheme } from "@/hooks";
import { CONFIG_VARS } from "@/constants";
import { Badge } from "primereact";
import { ORDERS_STATUS } from "@/constants/enums";

interface OrderDialogProps {
 order: IOrder | undefined;
}

const OrderDetailDataTable: React.FC<OrderDialogProps> = ({ order }) => {
 const { layoutColorMode } = useTheme();

 return (
  <>
   {order && (
    <table
     style={{
      border: `1px solid ${
       layoutColorMode === CONFIG_VARS.LIGHT
        ? "rgba(0, 0, 0, .05)"
        : "rgba(255, 255, 255, 0.6)"
      }`,
      borderCollapse: "collapse",
      textAlign: "left",
      width: "100%",
     }}
    >
     <tbody>
      <tr>
       <th
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        Order Id:
       </th>
       <td
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        {order.id.toString()}
       </td>
      </tr>
      <tr>
       <th
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        Customer Name:
       </th>
       <td
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        {order.customerName.toString()}
       </td>
      </tr>
      <tr>
       <th
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        Address Info:
       </th>
       <td
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        {order.addressInfo.toString()}
       </td>
      </tr>
      <tr>
       <th
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        Product Name:
       </th>
       <td
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        {order.productName.toString()}
       </td>
      </tr>
      <tr>
       <th
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        Date:
       </th>
       <td
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        {moment(order.date).format("DD/MM/YYYY").toString()}
       </td>
      </tr>
      <tr>
       <th
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        Price:
       </th>
       <td
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        {order.price.toString()}
       </td>
      </tr>
      <tr>
       <th
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        Status:
       </th>
       <td
        style={{
         border: `1px solid ${
          layoutColorMode === CONFIG_VARS.LIGHT
           ? "rgba(0, 0, 0, .05)"
           : "rgba(255, 255, 255, 0.6)"
         }`,

         padding: "8px",
        }}
       >
        <span
         className={`badge status-${
          order.status ? order.status.toLowerCase() : ""
         }`}
        >
         {order.status?.toString()}
        </span>
       </td>
      </tr>
     </tbody>
    </table>
   )}
  </>
 );
};

export default OrderDetailDataTable;
