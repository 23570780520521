import { axiosInstance } from "@/api/axios.instance";
import { ICategory } from "@/models";

export const getCategories = async (): Promise<ICategory[]> =>
 await axiosInstance.get(`/categories`).then((res) => res.data);

export const addCategory = async (category: ICategory) =>
 await axiosInstance.post(`/categories`, category);

export const updateCategory = async (category: ICategory) =>
 await axiosInstance.put(`/categories`, category);

export const deleteCategory = async (categoryId: bigint) =>
 await axiosInstance.delete(`/categories/${categoryId}`);
