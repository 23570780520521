import { InActiveOrderDataTable } from "@/components";
import React from "react";

const InActiveOrders = () => {
 return (
  <div className="datatable-crud">
   <div className="card">
    <InActiveOrderDataTable />
   </div>
  </div>
 );
};

export default InActiveOrders;
