import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import { CONFIG_VARS } from "@/constants";
import { useTheme } from "@/hooks";

export const AppConfig = () => {
 useEffect(() => {
  document.documentElement.style.fontSize = CONFIG_VARS.SCALE + "px";
 }, []);

 const { layoutColorMode } = useTheme();

 const replaceLink = useCallback((linkElement: any, href: string) => {
  linkElement.setAttribute("href", href);
 }, []);

 const getTheme = useCallback(() => {
  return layoutColorMode === CONFIG_VARS.LIGHT
   ? CONFIG_VARS.LIGHT_THEME
   : CONFIG_VARS.DARK_THEME;
 }, [layoutColorMode]);

 useEffect(() => {
  const themeElement = document.getElementById("theme-link");
  const themeHref = `./assets/themes/${getTheme()}/theme.css`;
  replaceLink(themeElement, themeHref);
 }, [replaceLink, layoutColorMode, getTheme]);

 return <></>;
};
