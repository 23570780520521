export const getBaseUrl = () => {
 let url;
 switch (process.env.NODE_ENV) {
  case "production":
   url = "https://www.ck-app.site/api";
   break;
  case "development":
   url = `http://194.163.160.61:5555`;
   break;
  default:
   url = `httpsnpm://www.ck-app.site/api`;
 }

 return url;
};
