import { useMobileMenuToggle } from "@/hooks";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import { IComponentProps } from "@/models";
import { isDesktop } from "@/utils";
import React, { useRef } from "react";

interface SidebarLayoutProps extends IComponentProps {}

const SidebarLayout: React.FC<SidebarLayoutProps> = ({ children }) => {
 const { setMobileMenuIsActive } = useMobileMenuToggle();
 const sidebarLayoutRef = useRef(null);

 useOnClickOutside(
  sidebarLayoutRef,
  () => !isDesktop() && setMobileMenuIsActive(false),
 );

 return (
  <div className="layout-sidebar" ref={sidebarLayoutRef}>
   {children}
  </div>
 );
};

export default SidebarLayout;
