export const langItems = [
 {
  key: "en",
  label: "English",
  imageCode: "gb-eng",
 },
 {
  key: "tr",
  label: "Turkish",
  imageCode: "tr",
 },
 {
  key: "ur",
  label: "Urdu",
  imageCode: "pk",
 },
];
