import { useFormik } from "formik";
import { Dialog, TabMenu } from "primereact";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataTableDialogFooter } from "../../common";
import {
 DropdownField,
 ErrorField,
 InputField,
 InputNumberField,
 RadioButtonField,
 RadioButtonBoxField,
} from "@/components/FormFields";
import { capitalizeFirstLetter, isFormFieldValid } from "@/utils";
import classNames from "classnames";
import ICampaign from "../../../../models/Entities/campaign/ICampaign";
import { statusRadioButtonFields } from "@/constants";
import { STATUS } from "@/constants/enums";

interface CampaignDialogProps {
 visible: boolean;
 campaign: Omit<ICampaign, "imageUrl">;
 isLoading: boolean;
 onHide: () => void;
 addCampaign: (campaign: Omit<ICampaign, "imageUrl">) => void;
}

const items = [
 { label: "Main", icon: "pi pi-fw pi-home" },
 { label: "SecondLanguage", icon: "pi pi-fw pi-language" },
];

const CampaignDialog: React.FC<CampaignDialogProps> = ({
 visible,
 campaign,
 isLoading,
 onHide,
 addCampaign,
}) => {
 const [status, setStatus] = useState(STATUS.INACTIVE);
 const [activeIndex, setActiveIndex] = useState(0);
 const { t } = useTranslation();

 const hideDialog = () => {
  console.log("here");
  onHide();
  formik.resetForm();
 };

 useEffect(() => {
  console.log("here");
  setStatus(campaign.status ?? STATUS.INACTIVE);
 }, [campaign]);

 const campaignOptions = [
  { name: "Step Count", value: "SC" },
  { name: "Daily Watch", value: "DW" },
  { name: "Regular Customer", value: "RC" },
  { name: "One Time", value: "WC" },
 ]?.map((campaign) => ({
  label: campaign.name,
  value: campaign.value,
 }));

 const setCampaignStatus = (value: any) => {
  setStatus(value);
  formik.values.status = value;
 };

 const formik = useFormik({
  initialValues: campaign,
  enableReinitialize: true,
  onSubmit: (data) => {
   addCampaign(data);
   setStatus(STATUS.INACTIVE);
  },
 });

 return (
  <Dialog
   visible={visible}
   style={{ width: "450px", minHeight: "750px" }}
   header={t("Campaign Details")}
   modal
   className="p-fluid"
   footer={
    <DataTableDialogFooter
     loading={isLoading}
     hideDialog={hideDialog}
     handleSubmit={formik.handleSubmit}
    />
   }
   onHide={hideDialog}
  >
   <TabMenu
    model={items}
    className="mb-5"
    activeIndex={activeIndex}
    onTabChange={(e) => setActiveIndex(e.index)}
   />
   <form onSubmit={formik.handleSubmit}>
    {activeIndex === 0 ? (
     <>
      <InputField
       id="name"
       value={formik.values.name}
       type="text"
       required
       autoFocus={true}
       onChange={formik.handleChange}
       labelName="name"
       labelText={capitalizeFirstLetter("name")}
       getFormErrorMessage={() => <ErrorField formik={formik} name={"name"} />}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "name"),
       })}
      />

      <DropdownField
       id="type"
       value={formik.values.type}
       type="text"
       required
       options={campaignOptions}
       onChange={formik.handleChange}
       labelName="type"
       labelText={capitalizeFirstLetter("type")}
       getFormErrorMessage={() => <ErrorField formik={formik} name={"type"} />}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "type"),
       })}
      />

      <InputField
       id="description"
       value={formik.values.description}
       type="text"
       required
       onChange={formik.handleChange}
       labelName="description"
       labelText={capitalizeFirstLetter("description")}
       getFormErrorMessage={() => (
        <ErrorField formik={formik} name={"description"} />
       )}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "description"),
       })}
      />

      <InputNumberField
       id="bonus"
       inputId="bonus"
       type="text"
       showButtons
       value={formik.values.bonus ?? ""}
       required
       onValueChange={formik.handleChange}
       labelName="bonus"
       labelText={capitalizeFirstLetter("Bonus")}
       getFormErrorMessage={() => <ErrorField formik={formik} name="bonus" />}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "bonus"),
       })}
      />

      <InputNumberField
       id="limitValue"
       inputId="limitValue"
       type="text"
       showButtons
       value={formik.values.limitValue ?? ""}
       required
       onValueChange={formik.handleChange}
       labelName="limitValue"
       labelText={capitalizeFirstLetter("Adv count for bonus")}
       getFormErrorMessage={() => (
        <ErrorField formik={formik} name="limitValue" />
       )}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "limitValue"),
       })}
      />

      <RadioButtonBoxField
       style={{ margin: "30px 0" }}
       className={classNames("field", {
        "p-invalid": isFormFieldValid(formik, "status"),
       })}
      >
       {statusRadioButtonFields.map((item, index) => (
        <RadioButtonField
         inputId={`status${index}`}
         name={"status"}
         key={index}
         value={item}
         onChange={(e: any) => setCampaignStatus(e.target.value)}
         checked={status === item}
         labelName={`status${index}`}
         labelText={item}
        />
       ))}
      </RadioButtonBoxField>
     </>
    ) : (
     <>
      <InputField
       id="name2lgn"
       value={formik.values.name2lgn}
       type="text"
       required
       autoFocus={true}
       onChange={formik.handleChange}
       labelName="name"
       labelText={capitalizeFirstLetter("Second Language Name")}
       getFormErrorMessage={() => <ErrorField formik={formik} name={"name"} />}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "name"),
       })}
      />
      <InputField
       id="description2lng"
       value={formik.values.description2lng}
       type="text"
       required
       onChange={formik.handleChange}
       labelName="description"
       labelText={capitalizeFirstLetter("Second Language Description")}
       getFormErrorMessage={() => (
        <ErrorField formik={formik} name={"description"} />
       )}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "description"),
       })}
      />
     </>
    )}
   </form>
  </Dialog>
 );
};

export default CampaignDialog;
