import React, { useState, useEffect } from "react";
import { Chart } from "primereact";
import { useGetAdsWatchCountPerDayData } from "@/hooks";

export default function AdsWatchCountPerDayChart() {
 const [chartData, setChartData] = useState({});
 const [chartOptions, setChartOptions] = useState({});
 const { adsWatchCountPerDay, getAdsWatchCountPerDayIsLoading } =
  useGetAdsWatchCountPerDayData();

 useEffect(() => {
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");
  const textColorSecondary = documentStyle.getPropertyValue(
   "--text-color-secondary",
  );
  const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
  const data = adsWatchCountPerDay?.map((item: any) => item[0]);
  const labels = adsWatchCountPerDay?.map((item: any) => item[1]);
  const chartData = {
   labels: labels ?? [],
   datasets: [
    {
     label: "Ads Watch Count Per Day",
     data: data ?? [],
     fill: false,
     borderColor: documentStyle.getPropertyValue("--blue-500"),
     tension: 0.4,
     fontSize: 20,
    },
   ],
  };
  const options = {
   maintainAspectRatio: false,
   aspectRatio: 0.6,
   plugins: {
    legend: {
     labels: {
      color: textColor,
     },
    },
   },
   scales: {
    x: {
     ticks: {
      color: textColorSecondary,
     },
     grid: {
      color: surfaceBorder,
     },
    },
    y: {
     ticks: {
      color: textColorSecondary,
     },
     grid: {
      color: surfaceBorder,
     },
    },
   },
  };

  setChartData(chartData);
  setChartOptions(options);
 }, [getAdsWatchCountPerDayIsLoading]);

 return (
  <div className="card mb-0">
   <Chart type="line" data={chartData} options={chartOptions} />
  </div>
 );
}
