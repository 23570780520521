import * as Yup from "yup";

export const mbiSchema = Yup.object().shape({
 title: Yup.string()
  .min(2, "Please enter at least 2 characters!")
  .max(50, "You can enter up to 50 characters!")
  .required("title is field required"),
 description: Yup.string()
  .min(2, "Please enter at least 2 characters!")
  .max(50, "You can enter up to 50 characters!")
  .required("title is field required"),
 type: Yup.string()
  .min(2, "Please enter at least 2 characters!")
  .max(50, "You can enter up to 50 characters!")
  .required("type is field required"),
});
