export const TRANSLATIONS_EN = {
 Home: "Home",
 Dashboard: "Dashboard",
 Customers: "Customers",
 "Customers Management": "Customers Management",
 "Products Management": "Products Management",
 Products: "Products",
 Suppliers: "Suppliers",
 Categories: "Categories",
 System: "System",
 Users: "Users",

 // Datatable and dialog
 "Manage Customers": "Manage Customers",
 "Manage Products": "Manage Products",
 "Manage Users": "Manage Users",
 "Manage Suppliers": "Manage Suppliers",
 "Manage Categories": "Manage Categories",
 "Customer Details": "Customer Details",
 "Product Details": "Product Details",
 "User Details": "User Details",
 "Supplier Details": "Supplier Details",
 "Category Details": "Category Details",
 "Are you sure you want to delete": "Are you sure you want to delete",
 "Showing {first} to {last} of {totalRecords} customers":
  "Showing {first} to {last} of {totalRecords} customers",
 "Showing {first} to {last} of {totalRecords} products":
  "Showing {first} to {last} of {totalRecords} products",
 "Showing {first} to {last} of {totalRecords} users":
  "Showing {first} to {last} of {totalRecords} users",
 "Showing {first} to {last} of {totalRecords} suppliers":
  "Showing {first} to {last} of {totalRecords} suppliers",
 "Showing {first} to {last} of {totalRecords} categories":
  "Showing {first} to {last} of {totalRecords} categories",
 Name: "Name",
 Surname: "Surname",
 Email: "Email",
 Password: "Password",
 "Gsm No": "Gsm No",
 Gsmno: "Gsm No",
 Address: "Address",
 City: "City",
 MAN: "MAN",
 WOMAN: "WOMAN",
 Birthdate: "Birth Date",
 Status: "Status",
 Type: "Type",
 New: "New",
 Delete: "Delete",
 "Search...": "Search...",
 Yes: "Yes",
 No: "No",
 Cancel: "Cancel",
 Save: "Save",
 Language: "Language",

 ENGLISH: "ENGLISH",
 TURKISH: "TURKISH",
 URDU: "URDU",
};
