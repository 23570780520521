import { ICampaign } from "@/models";
import { orderCampaigns } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useOrderCampaignsData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const { mutate, isLoading: orderCampaignsIsLoading } = useMutation(
  async (campaigns: Array<Omit<ICampaign, "imageUrl">>) =>
   await orderCampaigns(campaigns),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("campaigns");
    toastSuccess(toast, "Order campaign");
   },
   onError: () => {
    toastError(toast, "Not order campaign");
   },
  },
 );
 return {
  mutate,
  orderCampaignsIsLoading,
 };
};
