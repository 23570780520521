import { ICampaign, IProduct } from "@/models";
import {
 Button,
 FileUpload,
 FileUploadHeaderTemplateOptions,
 FileUploadHeaderTemplateType,
 FileUploadItemTemplateType,
 FileUploadSelectParams,
 ItemTemplateOptions,
 ProgressBar,
 Tag,
 Tooltip,
} from "primereact";
import React, { useEffect, useRef, useState } from "react";

interface Props {
 fileList: File[];
 setFileList: (fileList: File[]) => void;
 multiple?: boolean;
 dataType?: IProduct | ICampaign | null;
}

const ImageUploadField: React.FC<Props> = ({
 fileList,
 setFileList,
 multiple = true,
 dataType,
}) => {
 const [totalSize, setTotalSize] = useState(0);
 const fileUploadRef = useRef<FileUpload>(null);

 useEffect(() => {
  if (!fileList.length) {
   onTemplateClear();
   fileUploadRef.current?.clear();
  }
 }, [fileList]);

 const onTemplateSelect = (e: FileUploadSelectParams) => {
  let _totalSize = totalSize;
  const newArray: any = [];

  Array.from(e.files).forEach((file: File) => {
   _totalSize += file.size;
   newArray.push(file);
  });

  setTotalSize(_totalSize);
  setFileList([...fileList, ...newArray]);
 };

 const onTemplateRemove = (file: File, callback: Function) => {
  setTotalSize(totalSize - file.size);
  const newFileList = fileList.filter((f) => f !== file);
  setFileList([...newFileList]);
  callback();
 };

 const onTemplateClear = () => {
  setTotalSize(0);
 };

 const headerTemplate: FileUploadHeaderTemplateType = (
  options: FileUploadHeaderTemplateOptions,
 ) => {
  const { className, chooseButton, cancelButton } = options;
  const value = totalSize / 10000;

  const formatedValue =
   fileUploadRef && fileUploadRef.current
    ? fileUploadRef.current.formatSize(totalSize)
    : "0 B";

  return (
   <div
    className={className}
    style={{
     backgroundColor: "transparent",
     display: "flex",
     alignItems: "center",
     justifyContent: "space-between",
    }}
   >
    {chooseButton}
    {cancelButton}
    <ProgressBar
     value={value}
     displayValueTemplate={() => `${formatedValue} / 1 MB`}
     style={{
      width: "25%",
      minWidth: "150px",
      maxWidth: "300px",
      height: "20px",
      marginLeft: "auto",
     }}
    ></ProgressBar>
   </div>
  );
 };

 const itemTemplate: FileUploadItemTemplateType = (
  file: any,
  options: ItemTemplateOptions,
 ) => {
  const { formatSize, onRemove } = options;
  return (
   <div className="flex align-items-center flex-wrap">
    <div className="flex align-items-center" style={{ width: "40%" }}>
     <img
      alt={file.name}
      role="presentation"
      src={file.objectURL}
      width={200}
     />
     <span className="flex flex-column text-left ml-3">
      {file.name}
      <small>{new Date().toLocaleDateString()}</small>
     </span>
    </div>
    <Tag value={formatSize} severity="warning" className="px-3 py-2" />
    <Button
     type="button"
     icon="pi pi-times"
     className="p-button-outlined p-button-rounded p-button-danger ml-auto"
     onClick={() => onTemplateRemove(file, onRemove)}
    />
   </div>
  );
 };

 const emptyTemplate = () => {
  return (
   <div className="flex align-items-center flex-column">
    <i
     className="pi pi-image mt-3 p-5"
     style={{
      fontSize: "5em",
      borderRadius: "50%",
      backgroundColor: "var(--surface-b)",
      color: "var(--surface-d)",
     }}
    ></i>
    <span
     style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
     className="my-5"
    >
     Drag and Drop Image Here
    </span>
   </div>
  );
 };

 const chooseOptions = {
  icon: "pi pi-fw pi-images",
  iconOnly: true,
  className:
   "custom-choose-btn custom-image-upload-button p-button-rounded p-button-outlined",
 };
 const cancelOptions = {
  icon: "pi pi-fw pi-times",
  iconOnly: true,
  className:
   "custom-cancel-btn custom-image-upload-button p-button-danger p-button-rounded p-button-outlined",
 };

 return (
  <>
   <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
   <div className="flex justify-content-between">
    <span>Image</span>
    <span style={{ color: "red" }}>
     {dataType && "images" in dataType
      ? "( Image sizes must be 200px height and 200px width )"
      : "( Image sizes must be 200px height and 300px width )"}
    </span>
   </div>
   <FileUpload
    id="fileList"
    ref={fileUploadRef}
    name="fileList"
    customUpload
    multiple={multiple}
    accept="image/*"
    maxFileSize={1000000}
    onSelect={onTemplateSelect}
    onError={onTemplateClear}
    onClear={onTemplateClear}
    headerTemplate={headerTemplate}
    itemTemplate={itemTemplate}
    emptyTemplate={emptyTemplate}
    chooseOptions={chooseOptions}
    cancelOptions={cancelOptions}
    className="mt-2"
   />
  </>
 );
};

export default ImageUploadField;
