import { IProduct } from "@/models";
import { addProduct } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useAddProductData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const {
  mutate,
  isLoading: addProductIsLoading,
  isSuccess: addProductIsSuccess,
 } = useMutation(
  async (product: Omit<IProduct, "imageUrl">) => await addProduct(product),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("products");
    toastSuccess(toast, "Product Added");
   },
   onError: () => {
    toastError(toast, "Product not Added");
   },
  },
 );
 return { mutate, addProductIsLoading, addProductIsSuccess };
};
