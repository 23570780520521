import { getOrderList } from "@/services";
import { useQuery } from "react-query";

export const useGetOrderListData = () => {
 const { data: orderList, isLoading: getOrderListIsLoading } = useQuery(
  "orderList",
  getOrderList,
 );
 return { orderList, getOrderListIsLoading };
};
