import React from "react";

interface TopbarMenuItemsProps {
 title: string;
 iconClassName: string;
 onClick?: (e: any) => void;
}

const TopbarMenuItems: React.FC<TopbarMenuItemsProps> = ({
 title,
 iconClassName,
 onClick,
}) => {
 return (
  <li>
   <button className="p-link layout-topbar-button" onClick={onClick}>
    <i className={iconClassName} />
    <span>{title}</span>
   </button>
  </li>
 );
};

export default TopbarMenuItems;
