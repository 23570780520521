import {
 Column,
 Toast,
 Image,
 ColumnFilterElementTemplateOptions,
 InputNumber,
 Button,
 Tooltip,
} from "primereact";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import {
 DataTableSearchHeader,
 DataTableCrud,
 DataTableActionTemplate,
 DataTableToolbar,
 DataTableDeleteDialog,
 ProductDialog,
 ImageDialog,
 ProductStockDialog,
} from "@/components";
import { IProduct, IStock } from "@/models";
import { initialProduct, initialStock, productFilters } from "@/constants";
import {
 useAddProductData,
 useAddProductImagesData,
 useAddStockData,
 useDeleteProductData,
 useDeleteProductImageData,
 useGetCategoriesData,
 useGetProductData,
 useGetProductsData,
 useGetSuppliersData,
 useUpdateProductData,
} from "@/hooks";
import { useTranslation } from "react-i18next";
import NoImage from "@assets/images/no-image.png";

const ProductDataTable = () => {
 const [selectedProduct, setSelectedProduct] = useState<Omit<
  IProduct,
  "imageUrl"
 > | null>(null);
 const [filters, setFilters] = useState(productFilters);
 const [product, setProduct] =
  useState<Omit<IProduct, "imageUrl">>(initialProduct);
 const [stock, setStock] = useState<IStock>(initialStock);
 const [deleteProductDialog, setDeleteProductDialog] = useState(false);
 const [productDialog, setProductDialog] = useState(false);
 const [imageDialog, setImageDialog] = useState(false);
 const [productStockDialog, setProductStockDialog] = useState(false);
 const [isAddItem, setIsAddItem] = useState(false);
 const [fileList, setFileList] = useState<File[]>([]);

 const formData = new FormData();

 const toast = useRef<Toast>(null);

 const {
  mutate: addProductMutate,
  addProductIsLoading,
  addProductIsSuccess,
 } = useAddProductData(toast);
 const { mutate: addProductImages } = useAddProductImagesData(toast);
 const {
  mutate: addStockMutate,
  addStockIsLoading,
  addStockIsSuccess,
 } = useAddStockData(toast);
 const {
  mutate: updateProductMutate,
  updateProductIsLoading,
  updateProductIsSuccess,
 } = useUpdateProductData(toast);
 const { mutate: deleteProductMutate } = useDeleteProductData(toast);
 const { mutate: deleteProductImagesMutate } = useDeleteProductImageData(toast);
 const { products, getProductsIsLoading } = useGetProductsData();
 const {
  product: productDetail,
  getProductIsLoading,
  refetchProduct,
 } = useGetProductData(product.id);
 const { suppliers, getSuppliersRefetch } = useGetSuppliersData({
  enabled: false,
 });
 const { categories, getRefetchCategories } = useGetCategoriesData({
  enabled: false,
 });

 const { t } = useTranslation();

 useEffect(() => {
  if (addProductIsSuccess || updateProductIsSuccess) {
   hideProductDialog();
  }
 }, [addProductIsSuccess, updateProductIsSuccess]);

 useEffect(() => {
  if (addStockIsSuccess) {
   hideProductStockDialog();
  }
 }, [addStockIsSuccess]);

 const openDeleteProductDialog = (product?: IProduct) => {
  product && setProduct(product);
  setDeleteProductDialog(true);
 };

 const openAddProductDialog = async () => {
  await setProduct(initialProduct);
  await setIsAddItem(true);
  await getRefetchCategories();
  setProductDialog(true);
 };

 const openEditProductDialog = async (rowData: IProduct) => {
  const { imageUrl, ...rest } = rowData;
  await setProduct({ ...rest });
  await setIsAddItem(false);
  await getRefetchCategories();
  setProductDialog(true);
 };

 const openImageProductDialog = async (rowData: IProduct) => {
  await setProduct({ ...rowData });
  setImageDialog(true);
  refetchProduct();
 };

 const openStockProductDialog = async (rowData: IProduct) => {
  await setStock({ ...stock, product: { ...rowData } });
  await getSuppliersRefetch();
  setProductStockDialog(true);
 };

 const hideProductDialog = () => {
  setProductDialog(false);
 };

 const hideImageDialog = () => {
  setImageDialog(false);
 };

 const hideProductStockDialog = () => {
  setProductStockDialog(false);
 };

 const hideDeleteProductDialog = () => {
  setDeleteProductDialog(false);
 };

 const saveProduct = (product: Omit<IProduct, "imageUrl">) => {
  isAddItem ? addProductMutate(product) : updateProductMutate(product);
 };

 const saveImage = () => {
  formData.append("productId", product.id?.toString() ?? "");
  fileList.forEach((file) => {
   formData.append("file", file);
  });
  addProductImages(formData);
  setFileList([]);
  refetchProduct();
 };

 const deleteProduct = () => {
  product.id && deleteProductMutate(product.id);
  hideDeleteProductDialog();
 };

 const deleteImageProduct = (id: bigint | number) => {
  deleteProductImagesMutate(id);
  refetchProduct();
 };

 const saveStock = (stock: IStock) => {
  addStockMutate(stock);
 };

 const imageBodyTemplate = (rowData: IProduct) => {
  return (
   <Image
    src={`${rowData.imageUrl}`}
    alt="product"
    height="80"
    width="80"
    preview
    className="product-image"
    onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
     (e.target as HTMLImageElement).src = NoImage;
    }}
   />
  );
 };

 return (
  <>
   <Toast ref={toast} />

   <DataTableToolbar
    disableDeleteButton={!selectedProduct}
    openAddDialog={openAddProductDialog}
    openDeleteDialog={openDeleteProductDialog}
   />

   <DataTableCrud
    value={products}
    selection={selectedProduct}
    onSelectionChange={(e) => setSelectedProduct(e.value)}
    header={
     <DataTableSearchHeader
      title={t("Manage Products")}
      filters={filters}
      onChange={(e: any) => setFilters(e)}
     />
    }
    filters={filters}
    filterDisplay="row"
    globalFilterFields={["name", "type", "sellingPoint", "stockQuantity"]}
    loading={getProductsIsLoading}
    title="products"
    emptyMessage={t("No products found")}
   >
    <Column
     selectionMode="single"
     headerStyle={{ width: "3rem" }}
     exportable={false}
    ></Column>
    <Column
     field="name"
     header={t("Name")}
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder="Search by name"
    ></Column>
    <Column field="image" header="Image" body={imageBodyTemplate}></Column>
    <Column
     field="type"
     header={t("Type")}
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder="Search by type"
    ></Column>
    <Column
     field="sellingPoint"
     header={t("Price")}
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterElement={(option: ColumnFilterElementTemplateOptions) => {
      return (
       <InputNumber
        id="sellingPoint"
        placeholder="Search by price"
        type="text"
        onChange={(e) => option.filterApplyCallback(e.value?.toString() ?? "")}
       />
      );
     }}
    ></Column>
    <Column
     field="stockQuantity"
     header={t("Stock Quantity")}
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterElement={(option: ColumnFilterElementTemplateOptions) => {
      return (
       <InputNumber
        id="sellingPoint"
        placeholder="Search by stock quantity"
        type="text"
        onChange={(e) => option.filterApplyCallback(e.value?.toString() ?? "")}
       />
      );
     }}
    ></Column>
    <Column
     body={(rowData: IProduct) => (
      <>
       <DataTableActionTemplate
        openDeleteDialog={() => openDeleteProductDialog(rowData)}
        openEditDialog={async () => await openEditProductDialog(rowData)}
       />
       <Tooltip target="#p-images-button" position="bottom" content="Images" />
       <Tooltip
        target="#p-stock-button"
        position="bottom"
        content="Add Product Stock"
       />
       <Button
        id="p-images-button"
        icon="pi pi-images"
        className="p-button-rounded m-2"
        onClick={async () => await openImageProductDialog(rowData)}
       />

       <Button
        id="p-stock-button"
        icon="pi pi-shopping-cart"
        className="p-button-rounded p-button-danger m-2"
        onClick={async () => await openStockProductDialog(rowData)}
       />
      </>
     )}
     exportable={false}
     style={{ minWidth: "12rem" }}
    ></Column>
   </DataTableCrud>

   <DataTableDeleteDialog
    visible={deleteProductDialog}
    data={selectedProduct ?? product}
    onHide={hideDeleteProductDialog}
    onDelete={deleteProduct}
   />

   <ProductDialog
    visible={productDialog}
    product={product}
    categories={categories}
    isLoading={isAddItem ? addProductIsLoading : updateProductIsLoading}
    isAddItem={isAddItem}
    onHide={hideProductDialog}
    addProduct={saveProduct}
   />

   <ImageDialog
    visible={imageDialog}
    onHide={hideImageDialog}
    addImage={saveImage}
    fileList={fileList}
    setFileList={setFileList}
    isLoading={getProductIsLoading}
    data={productDetail}
    deleteImage={deleteImageProduct}
   />

   <ProductStockDialog
    visible={productStockDialog}
    onHide={hideProductStockDialog}
    suppliers={suppliers}
    isLoading={addStockIsLoading}
    stock={stock}
    addStock={saveStock}
   />
  </>
 );
};

export default ProductDataTable;
