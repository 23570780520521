import { setOrderCancel } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useSetOrderCancelData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const { mutate: cancelMutate } = useMutation(
  async (orderId: bigint) => await setOrderCancel(orderId),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("orders");
    toastSuccess(toast, "Order set cancel.");
   },
   onError: () => {
    toastError(toast, "Order not set cancel.");
   },
  },
 );
 return {
  cancelMutate,
 };
};
