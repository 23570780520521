import { IFieldProps } from "@/models";
import { MultiSelect, MultiSelectProps } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";

interface MultiSelectFieldProps extends MultiSelectProps, IFieldProps {}

const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
 getFormErrorMessage,
 labelName,
 labelText,
 ...rest
}) => {
 const { t } = useTranslation();
 return (
  <div className="field">
   <label htmlFor={labelName}>{t(labelText)}</label>
   <MultiSelect {...rest} />
   {getFormErrorMessage != null && getFormErrorMessage()}
  </div>
 );
};

export default MultiSelectField;
