import { getSuppliers } from "@/services";
import { useQuery } from "react-query";

export const useGetSuppliersData = (options?: {
 enabled: boolean | undefined;
}) => {
 const {
  data: suppliers,
  isLoading: getSuppliersIsLoading,
  refetch: getSuppliersRefetch,
 } = useQuery("suppliers", getSuppliers, options);
 return { suppliers, getSuppliersIsLoading, getSuppliersRefetch };
};
