import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/layout/layout.scss";
import { AppWrapper } from "./layouts";
import { AppConfig } from "./components";
import { Route, Routes } from "react-router-dom";
import {
 Customers,
 MainDashboard,
 OperatorDashboard,
 Login,
 Products,
 Users,
 Suppliers,
 Categories,
 NotFound,
 Campaigns,
 Orders,
 Mbi,
 InActiveOrders,
} from "./pages";
import { PrivateRoute } from "@/routes/PrivateRouter";
import { SpecialRoute } from "@/routes/SpecialRouter";
import { useRole } from "./hooks";
import { ROLE } from "./constants";

function App() {
 const { role } = useRole();
 return (
  <AppWrapper>
   <AppConfig />

   <Routes>
    <Route path="/login" element={<SpecialRoute />}>
     <Route path="/login" element={<Login />} />
    </Route>

    <Route path="/" element={<PrivateRoute />}>
     {role === ROLE.ADMIN && (
      <>
       <Route path="/stuff" element={<Users />} />
       <Route path="/user" element={<Customers />} />
      </>
     )}
     {role === ROLE.MANAGER && (
      <>
       <Route path="/user" element={<Customers />} />
      </>
     )}
     <Route
      path="/"
      element={
       role !== ROLE.OPERATOR ? <MainDashboard /> : <OperatorDashboard />
      }
     />
     <Route path="/product" element={<Products />} />
     <Route path="/supplier" element={<Suppliers />} />
     <Route path="/category" element={<Categories />} />
     <Route path="/campaign" element={<Campaigns />} />
     <Route path="/activeorder" element={<Orders />} />
     <Route path="/inactiveorder" element={<InActiveOrders />} />
     <Route path="/mbi" element={<Mbi />} />
     <Route path="*" element={<NotFound />} />
    </Route>
   </Routes>
  </AppWrapper>
 );
}

export default App;
