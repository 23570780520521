import { getUsers } from "@/services";
import { useQuery } from "react-query";

export const useGetUsersData = () => {
 const { data: users, isLoading: getUsersIsLoading } = useQuery(
  "users",
  getUsers,
 );
 return { users, getUsersIsLoading };
};
