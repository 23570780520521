import { axiosInstance } from "@/api/axios.instance";
import { IUser } from "@/models";

export const getUsers = async (): Promise<IUser[]> =>
 await axiosInstance.get(`/user`).then((res) => res.data);

export const addUser = async (user: IUser) =>
 await axiosInstance.post(`/user`, user);

export const deleteUser = async (userId: bigint) =>
 await axiosInstance.delete(`/user/${userId}`);
