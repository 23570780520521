import {
 signInWithEmailAndPassword,
 createUserWithEmailAndPassword,
 signOut,
 Auth,
 UserCredential,
} from "firebase/auth";

export const registerUser = async (
 auth: Auth,
 email: string,
 password: string,
): Promise<UserCredential> => {
 return await createUserWithEmailAndPassword(auth, email, password);
};

export const loginUser = async (
 auth: Auth,
 email: string,
 password: string,
): Promise<UserCredential> => {
 return await signInWithEmailAndPassword(auth, email, password);
};

export const logoutUser = async (auth: Auth): Promise<void> => {
 return await signOut(auth);
};
