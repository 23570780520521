import { getAddresses } from "@/services";
import { useQuery } from "react-query";

export const useGetAddressesData = (customerId: number | null) => {
 const { data: addresses, isLoading: getAddressesIsLoading } = useQuery(
  ["address", customerId],
  getAddresses,
 );
 return { addresses, getAddressesIsLoading };
};
