import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MenuContainer, SidebarLayout } from "@layouts/index";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { getMenuItems, ROLE } from "@/constants";
import { useRole } from "@/hooks";

const AppSubmenu = (props: any) => {
 const [activeIndex, setActiveIndex] = useState(null);
 const location = useLocation();
 const { t } = useTranslation();

 const onMenuItemClick = (event: any, item: any, index: any) => {
  // avoid processing disabled items
  if (item.disabled) {
   event.preventDefault();
   return true;
  }

  // execute command
  if (item.command) {
   item.command({ originalEvent: event, item });
  }

  if (index === activeIndex) setActiveIndex(null);
  else setActiveIndex(index);

  if (props.onMenuItemClick) {
   props.onMenuItemClick({
    originalEvent: event,
    item,
   });
  }
 };

 const renderLinkContent = (item: any) => {
  const submenuIcon = item.items && (
   <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
  );

  return (
   <React.Fragment>
    <i className={item.icon}></i>
    <span>{t(item.label)}</span>
    {submenuIcon}
   </React.Fragment>
  );
 };

 const renderLink = (item: any, i: number) => {
  const content = renderLinkContent(item);

  if (item.to) {
   const activeMenuItemStyle = classNames("menu-items", {
    "router-link-active router-link-exact-active":
     location.pathname === item.to,
   });
   return (
    <NavLink
     aria-label={item.label}
     role="menuitem"
     className={activeMenuItemStyle}
     to={item.to}
     key={i}
     onClick={(e) => onMenuItemClick(e, item, i)}
    >
     {content}
    </NavLink>
   );
  } else {
   return (
    <a
     aria-label={item.label}
     role="menuitem"
     href={item.url}
     className="p-ripple"
     key={i}
     onClick={(e) => onMenuItemClick(e, item, i)}
    >
     {content}
    </a>
   );
  }
 };

 const items =
  props.items &&
  props.items.map((item: any, i: any) => {
   const active = activeIndex === i;
   const styleClass = classNames(item.badgeStyleClass, {
    "layout-menuitem-category": props.root,
    "active-menuitem": active && !item.to,
   });

   if (props.root) {
    return (
     <li className={styleClass} key={i} role="none">
      {props.root === true && (
       <React.Fragment>
        <div className="layout-menuitem-root-text" aria-label={item.label}>
         {t(item.label)}
        </div>
        <AppSubmenu items={item.items} />
       </React.Fragment>
      )}
     </li>
    );
   } else {
    return (
     <li className={styleClass} key={i} role="none">
      {renderLink(item, i)}
      <CSSTransition
       classNames="layout-submenu-wrapper"
       timeout={{ enter: 1000, exit: 450 }}
       in={active}
       unmountOnExit
      >
       <AppSubmenu items={item.items} />
      </CSSTransition>
     </li>
    );
   }
  });

 return items ? (
  <ul className={props.className} role="menu">
   {items}
  </ul>
 ) : null;
};

const AppMenu = () => {
 const { role } = useRole();

 console.log("role", role);

 return (
  <SidebarLayout>
   <MenuContainer>
    <AppSubmenu
     items={getMenuItems(role)}
     className="layout-menu"
     root={true}
     role="menu"
    />
   </MenuContainer>
  </SidebarLayout>
 );
};

export default AppMenu;
