import { IUser } from "@/models";
import { addUser } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useAddUserData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const {
  mutate,
  isLoading: addUserIsLoading,
  isSuccess: addUserIsSuccess,
 } = useMutation(async (user: IUser) => await addUser(user), {
  onSuccess: () => {
   queryClient.invalidateQueries("users");
   toastSuccess(toast, "User Added");
  },
  onError: () => {
   toastError(toast, "User not Added");
  },
 });
 return { mutate, addUserIsLoading, addUserIsSuccess };
};
