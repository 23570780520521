import { Column, Toast, Image, Button, Tooltip } from "primereact";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import {
 DataTableSearchHeader,
 DataTableCrud,
 DataTableActionTemplate,
 DataTableToolbar,
 DataTableDeleteDialog,
 MbiDialog,
 ImageDialog,
 MbiSortDialog,
} from "@/components";
import { IMbi } from "@/models";
import { initialMbi, mbiFilters, STATUS } from "@/constants";
import {
 useAddMbiData,
 useDeleteMbiData,
 useGetMbisData,
 useOrderMbisData,
 useUpdateMbiData,
} from "@/hooks";
import { useTranslation } from "react-i18next";
import NoImage from "@assets/images/no-image.png";

const MbiDataTable = () => {
 const [selectedMbi, setSelectedMbi] = useState<IMbi | null>(null);
 const [filters, setFilters] = useState(mbiFilters);
 const [mbi, setMbi] = useState<Omit<IMbi, "imageUrl">>(initialMbi);
 const [deleteMbiDialog, setDeleteMbiDialog] = useState(false);
 const [mbiDialog, setMbiDialog] = useState(false);
 const [mbiSortDialog, setMbiSortDialog] = useState(false);
 const [imageDialog, setImageDialog] = useState(false);
 const [isAddItem, setIsAddItem] = useState(false);
 const [fileList, setFileList] = useState<File[]>([]);

 const formData = new FormData();

 const toast = useRef<Toast>(null);

 const {
  mutate: addMbiMutate,
  addMbiIsLoading,
  addMbiIsSuccess,
 } = useAddMbiData(toast);
 //  const { mutate: addProductImages } = useAddProductImagesData(toast);
 const {
  mutate: updateMbiMutate,
  updateMbiIsLoading,
  updateMbiIsSuccess,
 } = useUpdateMbiData(toast);
 const { mutate: deleteMbiMutate } = useDeleteMbiData(toast);
 const { mbis, getMbisIsLoading } = useGetMbisData();
 const { mutate: orderMbiMutate } = useOrderMbisData(toast);

 const { t } = useTranslation();

 useEffect(() => {
  if (addMbiIsSuccess || updateMbiIsSuccess) {
   setMbiDialog(false);
  }
 }, [addMbiIsSuccess, updateMbiIsSuccess]);

 const openDeleteMbiDialog = (Mbi?: IMbi) => {
  Mbi && setMbi(Mbi);
  setDeleteMbiDialog(true);
 };

 const openAddMbiDialog = () => {
  setMbi(initialMbi);
  setIsAddItem(true);
  setMbiDialog(true);
 };

 const openEditMbiDialog = (rowData: IMbi) => {
  const { imageUrl, ...rest } = rowData;
  setMbi({ ...rest });
  setIsAddItem(false);
  setMbiDialog(true);
 };

 const openImageMbiDialog = (rowData: IMbi) => {
  setMbi({ ...rowData });
  setImageDialog(true);
 };

 const openSortMbiDialog = () => {
  setMbiSortDialog(true);
 };

 const hideMbiDialog = () => {
  setMbiDialog(false);
 };

 const hideImageDialog = () => {
  setImageDialog(false);
 };

 const hideDeleteMbiDialog = () => {
  setDeleteMbiDialog(false);
 };

 const saveMbi = (mbi: Omit<IMbi, "imageUrl">) => {
  isAddItem ? addMbiMutate(mbi) : updateMbiMutate(mbi);
 };

 //  const saveImage = () => {
 //   formData.append("productId", product.id?.toString() ?? "");
 //   fileList.forEach((file) => {
 //    formData.append("file", file);
 //   });
 //   addProductImages(formData);
 //   setFileList([]);
 //  };

 const deleteMbi = () => {
  mbi.id && deleteMbiMutate(mbi.id);
  hideDeleteMbiDialog();
 };

 const imageBodyTemplate = (rowData: IMbi) => {
  return (
   <Image
    src={`${rowData.imageUrl}`}
    alt="mbi"
    height="80"
    width="80"
    preview
    className="mbi-image"
    onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
     (e.target as HTMLImageElement).src = NoImage;
    }}
   />
  );
 };

 return (
  <>
   <Toast ref={toast} />

   <DataTableToolbar
    disableDeleteButton={!selectedMbi}
    openAddDialog={openAddMbiDialog}
    openDeleteDialog={openDeleteMbiDialog}
   >
    <Button
     id="p-sort-button"
     label="Sort"
     icon="pi pi-sort"
     className="p-button-primary mr-2"
     onClick={openSortMbiDialog}
    />
   </DataTableToolbar>

   <DataTableCrud
    value={mbis}
    selection={selectedMbi}
    onSelectionChange={(e) => setSelectedMbi(e.value)}
    header={
     <DataTableSearchHeader
      title={t("Manage Main Board Items")}
      filters={filters}
      onChange={(e: any) => setFilters(e)}
     />
    }
    filters={filters}
    loading={getMbisIsLoading}
    title="Main Board Items"
   >
    <Column
     selectionMode="single"
     headerStyle={{ width: "3rem" }}
     exportable={false}
    />
    <Column
     field="title"
     header={t("Title")}
     sortable
     style={{ minWidth: "12rem" }}
    />
    <Column field="image" header="Image" body={imageBodyTemplate} />
    <Column
     field="description"
     header={t("Description")}
     sortable
     style={{ minWidth: "12rem" }}
    />
    <Column
     field="type"
     header={t("Type")}
     sortable
     style={{ minWidth: "12rem" }}
    />
    <Column
     field="status"
     header={t("Status")}
     sortable
     style={{ minWidth: "12rem" }}
     body={(rowData: IMbi) => (
      <span
       className={`badge status-${
        rowData.status ? rowData.status.toLowerCase() : ""
       }`}
      >
       {rowData.status}
      </span>
     )}
    />
    <Column
     body={(rowData: IMbi) => (
      <>
       <DataTableActionTemplate
        openDeleteDialog={() => openDeleteMbiDialog(rowData)}
        openEditDialog={() => openEditMbiDialog(rowData)}
       />
       <Tooltip target="#p-images-button" position="bottom" content="Images" />
       <Button
        id="p-images-button"
        icon="pi pi-images"
        className="p-button-rounded m-2"
        onClick={() => openImageMbiDialog(rowData)}
       />
      </>
     )}
     exportable={false}
     style={{ minWidth: "12rem" }}
    />
   </DataTableCrud>

   <DataTableDeleteDialog
    visible={deleteMbiDialog}
    data={selectedMbi ?? mbi}
    onHide={hideDeleteMbiDialog}
    onDelete={deleteMbi}
   />

   <MbiDialog
    visible={mbiDialog}
    mbi={mbi}
    isLoading={isAddItem ? addMbiIsLoading : updateMbiIsLoading}
    onHide={hideMbiDialog}
    addMbi={saveMbi}
   />

   <MbiSortDialog
    visible={mbiSortDialog}
    onHide={() => setMbiSortDialog(false)}
    isLoading={updateMbiIsLoading}
    orderMbis={orderMbiMutate}
    mbis={
     mbis
      ?.filter((campaign) => campaign.status === STATUS.ACTIVE)
      .sort((a, b) => a.sequence - b.sequence) ?? []
    }
   />

   <ImageDialog
    visible={imageDialog}
    onHide={hideImageDialog}
    addImage={() => {}}
    fileList={fileList}
    setFileList={setFileList}
   />
  </>
 );
};

export default MbiDataTable;
