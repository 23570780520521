import React from "react";
import { useMenuToggle, useMobileMenuToggle, useTheme } from "@/hooks";
import classNames from "classnames";
import { IComponentProps } from "@/models";
import { CONFIG_VARS } from "@/constants";

interface AppWrapperProps extends IComponentProps {}

const AppWrapper: React.FC<AppWrapperProps> = ({ children }) => {
 const { menuIsActive } = useMenuToggle();
 const { mobileMenuIsActive } = useMobileMenuToggle();
 const { layoutColorMode } = useTheme();

 const wrapperStyle = classNames(
  "layout-wrapper layout-static",
  { "layout-static-sidebar-inactive": !menuIsActive },
  { "layout-mobile-sidebar-active": mobileMenuIsActive },
  { "layout-theme-light": layoutColorMode === CONFIG_VARS.LIGHT },
  { "layout-theme-dark": layoutColorMode === CONFIG_VARS.DARK },
 );

 return <div className={wrapperStyle}>{children}</div>;
};

export default AppWrapper;
