import {
 Button,
 Column,
 ColumnFilterElementTemplateOptions,
 Dropdown,
 InputMask,
 Toast,
 Tooltip,
} from "primereact";
import { useEffect, useRef, useState } from "react";
import {
 DataTableSearchHeader,
 DataTableCrud,
 DataTableActionTemplate,
 DataTableDeleteDialog,
 DataTableStatusTemplate,
 CustomerDialog,
 CustomerAddressDialog,
} from "@/components";
import { ICustomer } from "@/models";
import {
 customerFilters,
 customerStatusTypes,
 initialCustomer,
} from "@/constants";
import {
 useDeleteCustomerData,
 useGetCustomersData,
 useUpdateCustomerData,
} from "@/hooks";
import { useTranslation } from "react-i18next";

const CustomerDataTable = () => {
 const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(
  null,
 );
 const [customer, setCustomer] = useState<ICustomer>(initialCustomer);
 const [filters, setFilters] = useState(customerFilters);
 const [deleteCustomerDialog, setDeleteCustomerDialog] = useState(false);
 const [customerDialog, setCustomerDialog] = useState(false);
 const [addressDialog, setAddressDialog] = useState(false);

 const toast = useRef<Toast>(null);

 const {
  mutate: updateCustomerMutate,
  updateCustomerIsLoading,
  updateCustomerIsSuccess,
 } = useUpdateCustomerData(toast);
 const { mutate: deleteCustomerMutate } = useDeleteCustomerData(toast);
 const { customers, getCustomersIsLoading } = useGetCustomersData();

 const { t } = useTranslation();

 useEffect(() => {
  if (updateCustomerIsSuccess) {
   hideCustomerDialog();
  }
 }, [updateCustomerIsSuccess]);

 const openDeleteCustomerDialog = (customer?: ICustomer) => {
  customer && setCustomer(customer);
  setDeleteCustomerDialog(true);
 };

 const openEditCustomerDialog = (rowData: ICustomer) => {
  setCustomer({ ...rowData });
  setCustomerDialog(true);
 };

 const openAddressDialog = (rowData: ICustomer) => {
  setCustomer({ ...rowData });
  setAddressDialog(true);
 };

 const hideCustomerDialog = () => {
  setCustomerDialog(false);
 };

 const hideAddressDialog = () => {
  setAddressDialog(false);
 };

 const hideDeleteCustomerDialog = () => {
  setDeleteCustomerDialog(false);
 };

 const saveCustomer = (customer: ICustomer) => {
  updateCustomerMutate(customer);
 };

 const deleteCustomer = () => {
  customer.id && deleteCustomerMutate(customer.id);
  hideDeleteCustomerDialog();
 };

 return (
  <>
   <Toast ref={toast} />

   <DataTableCrud
    value={customers}
    selection={selectedCustomer}
    onSelectionChange={(e: any) => setSelectedCustomer(e.value)}
    loading={getCustomersIsLoading}
    title="users"
    header={
     <DataTableSearchHeader
      title={t("Manage Users")}
      filters={filters}
      onChange={(e: any) => setFilters(e)}
     />
    }
    filters={filters}
    filterDisplay="row"
    globalFilterFields={["name", "surname", "gsmno", "city", "status", "type"]}
    emptyMessage={t("No users found")}
   >
    <Column
     selectionMode="single"
     headerStyle={{ width: "3rem" }}
     exportable={false}
    ></Column>
    <Column
     field="name"
     header={t("Name")}
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder="Search by name"
    ></Column>
    <Column
     field="surname"
     header={t("Surname")}
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder="Search by surname"
    ></Column>
    <Column
     field="gsmno"
     header={t("Gsm No")}
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterElement={(option: ColumnFilterElementTemplateOptions) => {
      return (
       <InputMask
        type="text"
        mask="(999) 999 99 99"
        className="p-column-filter"
        placeholder="Search by gsm no"
        onChange={(e) => {
         option.filterApplyCallback(e.target.value.replace(/[^0-9]/g, ""));
        }}
       />
      );
     }}
    ></Column>
    <Column
     field="city"
     header={t("City")}
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder="Search by city"
    ></Column>
    <Column
     field="status"
     header={t("Status")}
     sortable
     body={(rowData: ICustomer) => (
      <DataTableStatusTemplate status={rowData.status} />
     )}
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder="Search by status"
     filterElement={(options: ColumnFilterElementTemplateOptions) => {
      return (
       <Dropdown
        value={options.value}
        options={customerStatusTypes}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={(option: string) => {
         return (
          <span
           className={`badge status-${option ? option.toLowerCase() : ""}`}
          >
           {option}
          </span>
         );
        }}
        valueTemplate={(option: string) => {
         return (
          <span
           className={`badge status-${option ? option.toLowerCase() : ""}`}
          >
           {option}
          </span>
         );
        }}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
        style={{ minWidth: "13rem" }}
       />
      );
     }}
    ></Column>
    <Column
     field="type"
     header={t("Type")}
     sortable
     style={{ minWidth: "12rem" }}
     filter
     filterPlaceholder="Search by type"
    ></Column>
    <Column
     body={(rowData: ICustomer) => (
      <>
       <DataTableActionTemplate
        openDeleteDialog={() => openDeleteCustomerDialog(rowData)}
        openEditDialog={() => openEditCustomerDialog(rowData)}
       />
       <Tooltip target="#p-info-button" content="User Info" position="bottom" />
       <Button
        id="p-info-button"
        icon="pi pi-info-circle"
        className="p-button-rounded p-button-secondary m-2"
        onClick={() => openAddressDialog(rowData)}
       />
      </>
     )}
     exportable={false}
     style={{ minWidth: "12rem" }}
    ></Column>
   </DataTableCrud>

   <DataTableDeleteDialog
    visible={deleteCustomerDialog}
    data={selectedCustomer ?? customer}
    onHide={hideDeleteCustomerDialog}
    onDelete={deleteCustomer}
   />

   <CustomerDialog
    visible={customerDialog}
    customer={customer}
    isLoading={updateCustomerIsLoading}
    onHide={hideCustomerDialog}
    saveCustomer={saveCustomer}
   />
   <CustomerAddressDialog
    visible={addressDialog}
    customer={customer}
    onHide={hideAddressDialog}
   />
  </>
 );
};

export default CustomerDataTable;
