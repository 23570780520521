import { UserDataTable } from "@/components";

const Users = () => {
 return (
  <div className="datatable-crud">
   <div className="card">
    <UserDataTable />
   </div>
  </div>
 );
};

export default Users;
