import { deleteSupplier } from "@/services";
import { useMutation, useQueryClient } from "react-query";
import { Toast } from "primereact";
import { toastError, toastSuccess } from "@/utils";

export const useDeleteSupplierData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const { mutate, isLoading: deleteSupplierIsLoading } = useMutation(
  async (supplierId: bigint) => await deleteSupplier(supplierId),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("suppliers");
    toastSuccess(toast, "Supplier Deleted");
   },
   onError: () => {
    toastError(toast, "Supplier not Deleted");
   },
  },
 );
 return {
  mutate,
  deleteSupplierIsLoading,
 };
};
