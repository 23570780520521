import { useState } from "react";
import { IComponentProps } from "@/models";
import ThemeContext from "@/contexts/ThemeContext";
import { CONFIG_VARS } from "@/constants";

interface ThemeProviderProps extends IComponentProps {}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
 const [layoutColorMode, setLayoutColorMode] = useState<
  CONFIG_VARS.LIGHT | CONFIG_VARS.DARK
 >(CONFIG_VARS.LIGHT);
 return (
  <ThemeContext.Provider value={{ layoutColorMode, setLayoutColorMode }}>
   {children}
  </ThemeContext.Provider>
 );
};
