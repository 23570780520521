import { ISupplier } from "@/models";
import { updateSupplier } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateSupplierData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const {
  mutate,
  isLoading: updateSupplierIsLoading,
  isSuccess: updateSupplierIsSuccess,
 } = useMutation(
  async (supplier: ISupplier) => await updateSupplier(supplier),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("suppliers");
    toastSuccess(toast, "Supplier Updated");
   },
   onError: () => {
    toastError(toast, "Supplier not Updated");
   },
  },
 );
 return {
  mutate,
  updateSupplierIsLoading,
  updateSupplierIsSuccess,
 };
};
