import {
 ErrorField,
 InputField,
 DataTableDialogFooter,
 DropdownField,
 InputNumberField,
 MultiSelectField,
} from "@/components";
import { statusTypes } from "@/constants";
import { PRODUCT_TYPES } from "@/constants/enums";
import { ICategory, IProduct, ISupplier } from "@/models";
import { capitalizeFirstLetter, isFormFieldValid } from "@/utils";
import { productSchema } from "@/validations";
import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog, TabMenu } from "primereact";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface ProductDialogProps {
 visible: boolean;
 product: Omit<IProduct, "imageUrl">;
 categories: ICategory[] | undefined;
 isLoading: boolean;
 isAddItem: boolean;
 onHide: () => void;
 addProduct: (product: Omit<IProduct, "imageUrl">) => void;
}

const types = [
 PRODUCT_TYPES.DIGITAL,
 PRODUCT_TYPES.PHYSICAL,
 PRODUCT_TYPES.DRAW,
];

const items = [
 { label: "Main", icon: "pi pi-fw pi-home" },
 { label: "SecondLanguage", icon: "pi pi-fw pi-language" },
];

const statusOptions = statusTypes?.map((status) => ({
 label: status,
 value: status,
}));

const ProductDialog: React.FC<ProductDialogProps> = ({
 visible,
 product,
 isLoading,
 categories,
 isAddItem,
 onHide,
 addProduct,
}) => {
 const { t } = useTranslation();
 const [activeIndex, setActiveIndex] = useState(0);

 const categoriesOptions = categories?.map((category) => ({
  label: category.title,
  value: category,
 }));

 const typeOptions = types?.map((type) => ({
  label: type,
  value: type,
 }));

 const formik = useFormik({
  initialValues: product,
  enableReinitialize: true,
  validationSchema: productSchema,
  onSubmit: (data) => {
   addProduct(data);
  },
 });

 const hideDialog = () => {
  formik.resetForm();
  onHide();
 };

 return (
  <Dialog
   visible={visible}
   style={{ width: "450px" }}
   header={t("Product Details")}
   modal
   className="p-fluid"
   footer={
    <DataTableDialogFooter
     loading={isLoading}
     hideDialog={hideDialog}
     handleSubmit={formik.handleSubmit}
    />
   }
   onHide={hideDialog}
  >
   <TabMenu
    model={items}
    className="mb-5"
    activeIndex={activeIndex}
    onTabChange={(e) => setActiveIndex(e.index)}
   />
   <form onSubmit={formik.handleSubmit}>
    {activeIndex === 0 ? (
     <>
      <InputField
       id="name"
       value={formik.values.name ?? ""}
       type="text"
       required
       autoFocus={true}
       onChange={formik.handleChange}
       labelName="name"
       labelText={capitalizeFirstLetter("name")}
       getFormErrorMessage={() => <ErrorField formik={formik} name="name" />}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "name"),
       })}
      />
      <DropdownField
       id="type"
       name="type"
       value={formik.values.type}
       onChange={formik.handleChange}
       options={typeOptions ?? []}
       labelName="type"
       labelText={capitalizeFirstLetter("type")}
       getFormErrorMessage={() => <ErrorField formik={formik} name="type" />}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "type"),
       })}
      />
      <InputField
       id="brand"
       value={formik.values.brand ?? ""}
       type="text"
       required
       onChange={formik.handleChange}
       labelName="brand"
       labelText={capitalizeFirstLetter("brand")}
       getFormErrorMessage={() => <ErrorField formik={formik} name="brand" />}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "brand"),
       })}
      />
      <InputField
       id="longDesc"
       value={formik.values.longDesc ?? ""}
       type="text"
       required
       onChange={formik.handleChange}
       labelName="longDesc"
       labelText={capitalizeFirstLetter("Long Description")}
       getFormErrorMessage={() => (
        <ErrorField formik={formik} name="longDesc" />
       )}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "longDesc"),
       })}
      />
      <InputField
       id="shortDesc"
       value={formik.values.shortDesc ?? ""}
       type="text"
       required
       onChange={formik.handleChange}
       labelName="shortDesc"
       labelText={capitalizeFirstLetter("Short Description")}
       getFormErrorMessage={() => (
        <ErrorField formik={formik} name="shortDesc" />
       )}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "shortDesc"),
       })}
      />
      <InputNumberField
       id="sellingPoint"
       inputId="sellingPoint"
       showButtons
       mode="currency"
       currency="CKP"
       value={formik.values.sellingPoint ?? ""}
       required
       onValueChange={formik.handleChange}
       labelName="sellingPoint"
       labelText={capitalizeFirstLetter("Price")}
       getFormErrorMessage={() => (
        <ErrorField formik={formik} name="sellingPoint" />
       )}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "sellingPoint"),
       })}
      />
      <InputNumberField
       id="yearlyPurchaseLimit"
       inputId="yearlyPurchaseLimit"
       showButtons
       value={formik.values.yearlyPurchaseLimit ?? ""}
       required
       onValueChange={formik.handleChange}
       labelName="yearlyPurchaseLimit"
       labelText={capitalizeFirstLetter("Yearly Purchase Limit")}
       getFormErrorMessage={() => (
        <ErrorField formik={formik} name="yearlyPurchaseLimit" />
       )}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "yearlyPurchaseLimit"),
       })}
      />
      <MultiSelectField
       id="categories"
       name="categories"
       value={formik.values.categories}
       multiple={true}
       onChange={formik.handleChange}
       options={categoriesOptions ?? []}
       filter={true}
       labelName="categories"
       labelText={capitalizeFirstLetter("categories")}
       getFormErrorMessage={() => (
        <ErrorField formik={formik} name="categories" />
       )}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "categories"),
       })}
      />
      <InputField
       id="productLink"
       value={formik.values.productLink ?? ""}
       type="text"
       required
       onChange={formik.handleChange}
       labelName="productLink"
       labelText={capitalizeFirstLetter("Product Link")}
       getFormErrorMessage={() => (
        <ErrorField formik={formik} name="productLink" />
       )}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "productLink"),
       })}
      />
      {!isAddItem && (
       <DropdownField
        id="status"
        name="status"
        value={formik.values.status ?? ""}
        required
        autoFocus={true}
        onChange={formik.handleChange}
        options={statusOptions}
        filter={true}
        labelName="status"
        labelText={capitalizeFirstLetter("status")}
        getFormErrorMessage={() => <ErrorField formik={formik} name="status" />}
        className={classNames({
         "p-invalid": isFormFieldValid(formik, "status"),
        })}
       />
      )}
     </>
    ) : (
     <>
      <InputField
       id="name2lgn"
       value={formik.values.name2lgn ?? ""}
       type="text"
       required
       autoFocus={true}
       onChange={formik.handleChange}
       labelName="name2lgn"
       labelText={capitalizeFirstLetter("name")}
       getFormErrorMessage={() => (
        <ErrorField formik={formik} name="name2lgn" />
       )}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "name2lgn"),
       })}
      />
      <InputField
       id="brand2lng"
       value={formik.values.brand2lng ?? ""}
       type="text"
       required
       onChange={formik.handleChange}
       labelName="brand2lng"
       labelText={capitalizeFirstLetter("brand")}
       getFormErrorMessage={() => (
        <ErrorField formik={formik} name="brand2lng" />
       )}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "brand2lng"),
       })}
      />
      <InputField
       id="longDesc2lng"
       value={formik.values.longDesc2lng ?? ""}
       type="text"
       required
       onChange={formik.handleChange}
       labelName="longDesc2lng"
       labelText={capitalizeFirstLetter("Long Description")}
       getFormErrorMessage={() => (
        <ErrorField formik={formik} name="longDesc2lng" />
       )}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "longDesc2lng"),
       })}
      />
      <InputField
       id="shortDesc2lng"
       value={formik.values.shortDesc2lng ?? ""}
       type="text"
       required
       onChange={formik.handleChange}
       labelName="shortDesc2lng"
       labelText={capitalizeFirstLetter("Short Description")}
       getFormErrorMessage={() => (
        <ErrorField formik={formik} name="shortDesc2lng" />
       )}
       className={classNames({
        "p-invalid": isFormFieldValid(formik, "shortDesc2lng"),
       })}
      />
     </>
    )}
   </form>
  </Dialog>
 );
};

export default ProductDialog;
