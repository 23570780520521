import { IComponentProps } from "@/models";
import React from "react";

interface MenuContainerProps extends IComponentProps {}

const MenuContainer: React.FC<MenuContainerProps> = ({ children }) => {
 return <div className="layout-menu-container">{children}</div>;
};

export default MenuContainer;
