import React, { useState, useRef, useEffect } from "react";
import {
 DataTableActionTemplate,
 DataTableCrud,
 DataTableDeleteDialog,
 DataTableSearchHeader,
 DataTableToolbar,
 SupplierDialog,
} from "@/components";
import { Column, Toast } from "primereact";
import { initialSupplier } from "@/constants";
import { ISupplier } from "@/models";
import {
 useGetSuppliersData,
 useAddSupplierData,
 useUpdateSupplierData,
 useDeleteSupplierData,
} from "@/hooks/supplier";
import { useTranslation } from "react-i18next";

const SupplierDataTable = () => {
 const [supplier, setSupplier] = useState<ISupplier>(initialSupplier);
 const [deleteSupplierDialog, setDeleteSupplierDialog] = useState(false);
 const [isAddItem, setIsAddItem] = useState(false);
 const [selectedSupplier, setSelectedSupplier] = useState<any>(null);
 const [supplierDialog, setSupplierDialog] = useState(false);

 const toast = useRef<Toast>(null);

 const {
  mutate: addSupplierMutate,
  addSupplierIsLoading,
  addSupplierIsSuccess,
 } = useAddSupplierData(toast);
 const {
  mutate: updateSupplierMutate,
  updateSupplierIsLoading,
  updateSupplierIsSuccess,
 } = useUpdateSupplierData(toast);
 const { mutate: deleteSupplierMutate } = useDeleteSupplierData(toast);

 const { suppliers, getSuppliersIsLoading } = useGetSuppliersData();
 const { t } = useTranslation();

 useEffect(() => {
  if (addSupplierIsSuccess || updateSupplierIsSuccess) {
   hideSupplierDialog();
  }
 }, [addSupplierIsSuccess, updateSupplierIsSuccess]);

 const hideSupplierDialog = () => {
  setSupplierDialog(false);
 };

 const hideDeleteSupplierDialog = () => {
  setDeleteSupplierDialog(false);
 };

 const saveSupplier = (newSupplier: ISupplier) => {
  isAddItem
   ? addSupplierMutate(newSupplier)
   : updateSupplierMutate(newSupplier);
 };

 const openDeleteSupplierDialog = (supplier?: any) => {
  console.log(supplier);

  supplier && setSupplier(supplier);
  setDeleteSupplierDialog(true);
 };

 const openAddSupplierDialog = () => {
  setSupplier(initialSupplier);
  setIsAddItem(true);
  setSupplierDialog(true);
 };

 const openEditSupplierDialog = (rowData: ISupplier) => {
  setSupplier({ ...rowData });
  setIsAddItem(false);
  setSupplierDialog(true);
 };

 const deleteSupplier = () => {
  supplier.id && deleteSupplierMutate(supplier.id);
  hideDeleteSupplierDialog();
 };

 return (
  <>
   <Toast ref={toast} />
   <DataTableToolbar
    openDeleteDialog={openDeleteSupplierDialog}
    openAddDialog={openAddSupplierDialog}
    disableDeleteButton={!selectedSupplier}
   />

   <DataTableCrud
    value={suppliers}
    title="suppliers"
    selection={selectedSupplier}
    filters={null}
    loading={getSuppliersIsLoading}
    onSelectionChange={(e: any) => setSelectedSupplier(e.value)}
    header={
     <DataTableSearchHeader
      title={t("Manage Suppliers")}
      filters={null}
      onChange={(e: any) => {}}
     />
    }
   >
    <Column
     selectionMode="single"
     headerStyle={{ width: "3rem" }}
     exportable={false}
    ></Column>
    <Column
     field="name"
     header={"Name"}
     sortable
     style={{ minWidth: "12rem" }}
    ></Column>
    <Column
     field="gsmno"
     header={"Gsm No"}
     sortable
     style={{ minWidth: "12rem" }}
    ></Column>
    <Column
     body={(rowData: ISupplier) => (
      <DataTableActionTemplate
       openDeleteDialog={() => openDeleteSupplierDialog(rowData)}
       openEditDialog={() => openEditSupplierDialog(rowData)}
      />
     )}
     exportable={false}
     style={{ minWidth: "12rem" }}
    ></Column>
   </DataTableCrud>

   <DataTableDeleteDialog
    visible={deleteSupplierDialog}
    data={selectedSupplier ?? supplier}
    onHide={hideDeleteSupplierDialog}
    onDelete={deleteSupplier}
   />

   <SupplierDialog
    visible={supplierDialog}
    supplier={supplier}
    isLoading={isAddItem ? addSupplierIsLoading : updateSupplierIsLoading}
    onHide={hideSupplierDialog}
    addSupplier={saveSupplier}
   />
  </>
 );
};

export default SupplierDataTable;
