import { getCampaigns } from "@/services";
import { useQuery } from "react-query";

export const useGetCampaignsData = (enabled?: boolean) => {
 const {
  data: campaigns,
  isLoading: getCampaignsIsLoading,
  refetch: refetchGetCampaigns,
 } = useQuery("campaigns", getCampaigns, {
  enabled,
 });
 return { campaigns, getCampaignsIsLoading, refetchGetCampaigns };
};
