import { deleteProduct } from "@/services";
import { useMutation, useQueryClient } from "react-query";
import { Toast } from "primereact";
import { toastError, toastSuccess } from "@/utils";

export const useDeleteProductData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const { mutate, isLoading: deleteProductIsLoading } = useMutation(
  async (productId: bigint) => await deleteProduct(productId),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("products");
    toastSuccess(toast, "Product Deleted");
   },
   onError: () => {
    toastError(toast, "Product not Deleted");
   },
  },
 );
 return {
  mutate,
  deleteProductIsLoading,
 };
};
