import { langItems } from "@/constants";
import { Button, Dialog, Image } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";

interface LanguageDialogProps {
 visible: boolean;
 onHide: () => void;
}

const LanguageDialog: React.FC<LanguageDialogProps> = ({ visible, onHide }) => {
 const { t, i18n } = useTranslation();

 return (
  <Dialog
   visible={visible}
   style={{ width: "500px" }}
   header={t("Language")}
   modal
   dismissableMask
   onHide={onHide}
  >
   {langItems.map((lang, index) => (
    <Button
     key={index}
     className="p-button-text p-button-raised p-button-rounded"
     style={{ margin: ".5rem", width: "125px" }}
     onClick={async () => await i18n.changeLanguage(lang.key)}
    >
     <Image
      src={`https://flagcdn.com/24x18/${lang.imageCode.toLocaleLowerCase()}.png`}
      width="24"
      height="18"
      style={{ marginRight: ".5rem" }}
      alt={t(lang.key.toUpperCase())}
     ></Image>
     <span>{t(lang.label.toUpperCase())}</span>
    </Button>
   ))}
  </Dialog>
 );
};

export default LanguageDialog;
