import { getCustomerCountOneMonth } from "@/services";
import { useQuery } from "react-query";

export const useGetCustomerCountOneMonthData = () => {
 const {
  data: customerCountOneMonth,
  isLoading: getCustomerCountOneMonthIsLoading,
 } = useQuery("customerCountOneMonth", getCustomerCountOneMonth);
 return { customerCountOneMonth, getCustomerCountOneMonthIsLoading };
};
