import React from "react";

interface RadioButtonBoxFieldProps {
 children: React.ReactNode;
 style: object;
 className: string;
}

const RadioButtonBoxField: React.FC<RadioButtonBoxFieldProps> = ({
 children,
 ...rest
}) => {
 return <div {...rest}>{children}</div>;
};

export default RadioButtonBoxField;
