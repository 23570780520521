import { IMbi } from "@/models";
import { orderMbis } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useOrderMbisData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const { mutate, isLoading: orderMbisIsLoading } = useMutation(
  async (mbis: Array<Omit<IMbi, "imageUrl">>) => await orderMbis(mbis),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("Mbis");
    toastSuccess(toast, "Order mbi");
   },
   onError: () => {
    toastError(toast, "Not order mbi");
   },
  },
 );
 return {
  mutate,
  orderMbisIsLoading,
 };
};
