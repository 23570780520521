import classNames from "classnames";
import { useFormik } from "formik";
import { Dialog } from "primereact";
import React from "react";
import { supplierTypes } from "@/constants";
import {
 InputField,
 InputTextAreaField,
 ErrorField,
 DataTableDialogFooter,
 InputMaskField,
 DropdownField,
} from "@/components";
import { isFormFieldValid, capitalizeFirstLetter } from "@/utils";
import { useTranslation } from "react-i18next";
import { ISupplier } from "@/models";

interface SupplierDialogProps {
 visible: boolean;
 supplier: ISupplier;
 isLoading: boolean;
 onHide: () => void;
 addSupplier: (supplier: ISupplier) => void;
}

const typeOptions = supplierTypes?.map((type) => ({
 label: type,
 value: type,
}));

const SupplierDialog: React.FC<SupplierDialogProps> = ({
 visible,
 supplier,
 isLoading,
 onHide,
 addSupplier,
}) => {
 const { t } = useTranslation();

 const hideDialog = () => {
  onHide();
  formik.resetForm();
 };

 const formik = useFormik({
  initialValues: supplier,
  enableReinitialize: true,
  onSubmit: (data) => {
   addSupplier(data);
  },
 });

 return (
  <Dialog
   visible={visible}
   style={{ width: "450px" }}
   header={t("Supplier Details")}
   modal
   className="p-fluid"
   footer={
    <DataTableDialogFooter
     loading={isLoading}
     hideDialog={hideDialog}
     handleSubmit={formik.handleSubmit}
    />
   }
   onHide={hideDialog}
  >
   <form onSubmit={formik.handleSubmit}>
    <InputField
     id="name"
     value={formik.values.name ?? ""}
     type="text"
     required
     autoFocus={true}
     onChange={formik.handleChange}
     labelName="name"
     labelText={capitalizeFirstLetter("name")}
     getFormErrorMessage={() => <ErrorField formik={formik} name={"name"} />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "name"),
     })}
    />

    <InputMaskField
     id="telno"
     value={formik.values.telno ?? ""}
     type="gsmno"
     mask="(999) 999 99 99"
     required
     onChange={formik.handleChange}
     labelName="telno"
     labelText={capitalizeFirstLetter("telno")}
     getFormErrorMessage={() => <ErrorField formik={formik} name={"telno"} />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "telno"),
     })}
    />

    <DropdownField
     id="type"
     name="type"
     value={formik.values.type}
     onChange={formik.handleChange}
     options={typeOptions ?? []}
     labelName="type"
     labelText={capitalizeFirstLetter("type")}
     getFormErrorMessage={() => <ErrorField formik={formik} name="type" />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "type"),
     })}
    />

    <InputTextAreaField
     id={"address"}
     value={formik.values.address ?? ""}
     required
     autoResize
     onChange={formik.handleChange}
     labelName={"address"}
     labelText={capitalizeFirstLetter("address")}
     getFormErrorMessage={() => <ErrorField formik={formik} name={"address"} />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "address"),
     })}
    />
   </form>
  </Dialog>
 );
};

export default SupplierDialog;
