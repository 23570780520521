import { axiosInstance } from "@/api/axios.instance";
import { ICampaign } from "@/models";

export const getCampaigns = async (): Promise<ICampaign[]> =>
 await axiosInstance.get(`/campaigns`).then((res) => res.data);

export const addCampaign = async (campaign: Omit<ICampaign, "imageUrl">) =>
 await axiosInstance.post(`/campaigns`, campaign);

export const updateCampaign = async (campaign: Omit<ICampaign, "imageUrl">) =>
 await axiosInstance.put(`/campaigns`, campaign);

export const deleteCampaign = async (campaignId: bigint) =>
 await axiosInstance.delete(`/campaigns/${campaignId}`);

export const deleteCampaignImage = async (
 campaignId: bigint | number,
): Promise<any> => await axiosInstance.delete(`/campaigns/image/${campaignId}`);

export const addCampaignImages = async (campaignImages: FormData) =>
 await axiosInstance.post(`/campaigns/images`, campaignImages, {
  headers: {
   "Content-Type": "multipart/form-data",
  },
 });

export const orderCampaigns = async (
 campaigns: Array<Omit<ICampaign, "imageUrl">>,
) => await axiosInstance.post(`/campaigns/order`, campaigns);
