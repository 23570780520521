import { STATUS } from "@/constants";
import React from "react";

interface DataTableStatusTemplateProps {
 status: string;
}

const DataTableStatusTemplate: React.FC<DataTableStatusTemplateProps> = ({
 status,
}) => {
 return (
  <span className={`badge status-${status ? status.toLowerCase() : ""}`}>
   {status}
  </span>
 );
};

export default DataTableStatusTemplate;
