import {
 ICustomer,
 ILoginFields,
 IProduct,
 IUser,
 ISupplier,
 ICategory,
 ICampaign,
 IMbi,
 IStock,
} from "@/models";
import { ROLE, STATUS } from "./enums";

export const initialCustomer: ICustomer = {
 id: null,
 guid: "",
 username: "",
 name: "",
 surname: "",
 email: "",
 emailConfirm: false,
 emailConfirmCode: "",
 password: "",
 gsmno: "",
 address: "",
 birthDate: null,
 gender: null,
 city: "",
 status: "",
 type: "",
 lastlogindate: null,
 moneyAmount: 0,
};

export const initialLogin: ILoginFields = {
 email: "",
 password: "",
};

export const initialProduct: Omit<IProduct, "imageUrl"> = {
 id: null,
 name: "",
 type: null,
 brand: "",
 model: "",
 longDesc: "",
 shortDesc: "",
 sellingPoint: 0,
 stockQuantity: 0,
 categories: [],
 images: [
  {
   filePath: "",
   id: 0,
   name: "",
   type: "",
  },
 ],
 yearlyPurchaseLimit: 1,
 status: STATUS.INACTIVE,
 name2lgn: "",
 brand2lng: "",
 longDesc2lng: "",
 shortDesc2lng: "",
 productLink: "",
};

export const initialMbi: IMbi = {
 action: null,
 description: "",
 id: null,
 imageUrl: "",
 sequence: 0,
 status: STATUS.INACTIVE,
 targetId: 0,
 targetUrl: null,
 title: "",
 type: null,
 url: "",
 product: "",
 campaign: "",
};

export const initialUser: IUser = {
 id: null,
 firebaseUid: "",
 email: "",
 password: "",
 name: "",
 type: "",
 role: ROLE.OPERATOR,
};

export const initialSupplier: ISupplier = {
 id: null,
 name: "",
 address: "",
 telno: "",
 type: "",
};

export const initialCategory: ICategory = {
 id: null,
 title: "",
 description: "",
 productList: null,
 title2lng: "",
 description2lng: "",
};

export const initialStock: IStock = {
 id: null,
 date: null,
 billNo: "",
 unitPrice: 0,
 amount: 0,
 total: 0,
 product: initialProduct,
 productList: [],
 supplier: null,
};

export const initialCampaign: Omit<ICampaign, "imageUrl"> = {
 id: null,
 name: "",
 type: "",
 description: "",
 amount: 0,
 bonus: 0,
 status: STATUS.INACTIVE,
 stepCountLimit: 0,
 sequence: 0,
 limitValue: 0,
 name2lgn: "",
 description2lng: "",
};
