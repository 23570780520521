import { Navigate, Outlet } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "@/config/firebase";
import { AuthWrapper } from "@/layouts";
import { ProgressSpinner } from "primereact";

export const SpecialRoute = () => {
 const [user, loading] = useAuthState(auth);

 return loading ? (
  <AuthWrapper>
   <ProgressSpinner
    className="p-progress-spinner"
    style={{ width: "50px", height: "50px" }}
    strokeWidth="8"
    fill="var(--surface-ground)"
    animationDuration=".5s"
   />
  </AuthWrapper>
 ) : !user ? (
  <AuthWrapper>
   <Outlet />
  </AuthWrapper>
 ) : (
  <Navigate to="/" />
 );
};
