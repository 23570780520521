import { IMbi } from "@/models";
import { addMbi } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useAddMbiData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const {
  mutate,
  isLoading: addMbiIsLoading,
  isSuccess: addMbiIsSuccess,
 } = useMutation(async (mbi: Omit<IMbi, "imageUrl">) => await addMbi(mbi), {
  onSuccess: () => {
   queryClient.invalidateQueries("mbis");
   toastSuccess(toast, "Main Board Item Added");
  },
  onError: () => {
   toastError(toast, "Main Board Item not Added");
  },
 });
 return { mutate, addMbiIsLoading, addMbiIsSuccess };
};
