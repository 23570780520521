import { setOrderPreparing } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useSetOrderPreparingData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const { mutate: preparingMutate } = useMutation(
  async (orderId: bigint) => await setOrderPreparing(orderId),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("orders");
    toastSuccess(toast, "Order set preparing.");
   },
   onError: () => {
    toastError(toast, "Order not set preparing.");
   },
  },
 );
 return {
  preparingMutate,
 };
};
