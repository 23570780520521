import { RadioButton } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";

interface RadioButtonFieldProps {
 inputId: string;
 name: string;
 value: any;
 classname?: string;
 onChange: (e: any) => void;
 required?: boolean;
 checked: boolean;
 labelName: string;
 labelText: string;
}

const RadioButtonField: React.FC<RadioButtonFieldProps> = ({
 labelName,
 labelText,
 ...rest
}) => {
 const { t } = useTranslation();
 return (
  <div className="field-radiobutton">
   <RadioButton {...rest} />
   <label htmlFor={labelName}>{t(labelText)}</label>
  </div>
 );
};

export default RadioButtonField;
