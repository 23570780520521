import { getAdsWatchCountPerDay } from "@/services";
import { useQuery } from "react-query";

export const useGetAdsWatchCountPerDayData = () => {
 const {
  data: adsWatchCountPerDay,
  isLoading: getAdsWatchCountPerDayIsLoading,
 } = useQuery("adsWatchCountPerDay", getAdsWatchCountPerDay);
 return { adsWatchCountPerDay, getAdsWatchCountPerDayIsLoading };
};
