import { ICategory } from "@/models";
import { addCategory } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useAddCategoryData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const {
  mutate,
  isLoading: addCategoryIsLoading,
  isSuccess: addCategoryIsSuccess,
 } = useMutation(async (category: ICategory) => await addCategory(category), {
  onSuccess: () => {
   queryClient.invalidateQueries("categories");
   toastSuccess(toast, "Category Added");
  },
  onError: () => {
   toastError(toast, "Category not Added");
  },
 });
 return {
  mutate,
  addCategoryIsLoading,
  addCategoryIsSuccess,
 };
};
