import { Navigate, Outlet } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "@/config/firebase";
import { useEffect } from "react";
import { useRequestInterceptor } from "@/hooks/useRequestInterceptor";
import { AuthWrapper, DashboardWrapper } from "@/layouts";
import { ProgressSpinner } from "primereact";
import { useRole } from "@/hooks";

export const PrivateRoute = () => {
 const [user, loading] = useAuthState(auth);
 const requestInterceptor = useRequestInterceptor();
 const { setRole } = useRole();

 useEffect(() => {
  setRoleAndToken();
 }, [user, loading]);

 const setRoleAndToken = async () => {
  setRole((await user?.getIdTokenResult())?.claims.role);
  await requestInterceptor();
 };

 return loading ? (
  <AuthWrapper>
   <ProgressSpinner
    className="p-progress-spinner"
    style={{ width: "50px", height: "50px" }}
    strokeWidth="5"
    fill="var(--surface-ground)"
    animationDuration=".5s"
   />
  </AuthWrapper>
 ) : user ? (
  <DashboardWrapper>
   <Outlet />
  </DashboardWrapper>
 ) : (
  <Navigate to="/login" />
 );
};
