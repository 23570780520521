import { axiosInstance } from "@/api/axios.instance";
import { IAddress } from "@/models/Entities/address";
import { QueryFunctionContext } from "react-query";

export const getAddresses = async ({
 queryKey,
}: QueryFunctionContext<[string, number | null]>): Promise<IAddress[]> => {
 const [, customerId] = queryKey;
 return await axiosInstance
  .get(`/customer/addresses`, {
   params: {
    customerId,
   },
  })
  .then((res) => res.data);
};
