import { IFieldProps } from "@/models";
import { InputMask, InputMaskProps } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";

interface InputMaskFieldProps extends InputMaskProps, IFieldProps {}

const InputMaskField: React.FC<InputMaskFieldProps> = ({
 getFormErrorMessage,
 labelName,
 labelText,
 ...rest
}) => {
 const { t } = useTranslation();
 return (
  <div className="field">
   <label htmlFor={labelName}>{t(labelText)}</label>
   <InputMask {...rest} />
   {getFormErrorMessage != null && getFormErrorMessage()}
  </div>
 );
};

export default InputMaskField;
