import { IMbi } from "@/models";
import { updateMbi } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateMbiData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const {
  mutate,
  isLoading: updateMbiIsLoading,
  isSuccess: updateMbiIsSuccess,
 } = useMutation(async (mbi: Omit<IMbi, "imageUrl">) => await updateMbi(mbi), {
  onSuccess: () => {
   queryClient.invalidateQueries("mbis");
   toastSuccess(toast, "Main Board Item Updated");
  },
  onError: () => {
   toastError(toast, "Main Board Item not Updated");
  },
 });
 return { mutate, updateMbiIsLoading, updateMbiIsSuccess };
};
