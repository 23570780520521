import { CONFIG_VARS } from "@/constants";
import { createContext, Dispatch, SetStateAction } from "react";

const layoutColorMode: CONFIG_VARS.LIGHT | CONFIG_VARS.DARK = CONFIG_VARS.LIGHT;
const setLayoutColorMode: Dispatch<
 SetStateAction<CONFIG_VARS.LIGHT | CONFIG_VARS.DARK>
> = () => {};

export default createContext({
 layoutColorMode: layoutColorMode as CONFIG_VARS.LIGHT | CONFIG_VARS.DARK,
 setLayoutColorMode,
});
