import { getOrders } from "@/services";
import { useQuery } from "react-query";

export const useGetOrdersData = () => {
 const { data: orders, isLoading: getOrdersIsLoading } = useQuery(
  "orders",
  getOrders,
 );
 return { orders, getOrdersIsLoading };
};
