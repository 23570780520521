import { ICategory } from "@/models";
import { updateCategory } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { Toast } from "primereact";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateCategoryData = (toast: React.RefObject<Toast>) => {
 const queryClient = useQueryClient();
 const {
  mutate,
  isLoading: updateCategoryIsLoading,
  isSuccess: updateCategoryIsSuccess,
 } = useMutation(
  async (category: ICategory) => await updateCategory(category),
  {
   onSuccess: () => {
    queryClient.invalidateQueries("categories");
    toastSuccess(toast, "Category Updated");
   },
   onError: () => {
    toastError(toast, "Category not Updated");
   },
  },
 );
 return {
  mutate,
  updateCategoryIsLoading,
  updateCategoryIsSuccess,
 };
};
